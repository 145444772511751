/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDown } from '@mybridge/icons';
import { Button, Scrollable } from '@mybridge/ui';
import { Avatar } from '@mybridge/ui/avatar';
import { Card } from '@mybridge/ui/card';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserCardSkeleton } from 'v4/components/user-card-skeleton';
import { UserDetailsPopover } from 'v4/components/user-details-popover';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import styles from '../../header/index.module.scss';

import {
  MATRIX_PRESENCE,
  UserPresenceDot,
} from 'v4/components/user-presence-dot';
import {
  getUserFullName,
  getUserPosition,
  getUserProfilePic,
} from 'v4/lib/commons';
import { getAllConnectionsData } from 'v4/store/actions/connection.actions';
import { startChatWithUser } from 'v4/store/actions/chat.actions';
import { Divider } from '@chakra-ui/react';
import { NetworkListingNotFound } from 'v4/snippets/profile/404';

export const DashboardMyCircle = ({ ...props }) => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { allDegreeConnectionsArr } = useSelector((state) => state.connection);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [connectionsLimit, setConnectionsLimit] = useState(8);
  const { startChatSingle, startMeetingSingle, findDirectRoomId } =
    useContext(MatrixContext);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (allDegreeConnectionsArr?.length === 0) {
      dispatch(getAllConnectionsData())
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {

      setTimeout(() => {
        setLoading(false);
        
      }, 3000);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getAllConnectionsData());
    }, 60000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dispatch(getAllConnectionsData());
  }, [userProfileInfo, loggedIn]);

  const connections = allDegreeConnectionsArr?.[0] ?? [];
  const sortedConnections = useMemo(() => {
    const nc = [...(connections ?? [])]?.sort?.(
      (a, b) =>
        MATRIX_PRESENCE?.[b?.matrix_presence]?.priority -
        MATRIX_PRESENCE?.[a?.matrix_presence]?.priority
    );
    return nc;
  }, [connections, userProfileInfo]);

  const handleScrollEnd = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setConnectionsLimit(connectionsLimit * 2);
    }
  };

  if (!loading && (!connections || connections.length <= 0))
    return (
      <>
        <Stack pos="relative">
          <HStack zIndex={1} top="0" p={3} pb={1} pos="sticky">
            <Heading
              flex={1}
              pl={3}
              fontWeight="400"
              fontSize="md"
              color="brandPrimary.500"
            >
              myNetwork
            </Heading>
          </HStack>

          <Stack>
            <Text pl={6} fontSize={'16px'} fontWeight="400" color="#C6C6C6">You don’t have any connections yet.</Text>
          <Text  pl={6} fontSize={'14px'} fontWeight="400" color="#C6C6C6">
            Explore new opportunities and insights by tapping into your
            connections and their networks.
          </Text>
          <Text
            pl={6}
            fontWeight="400"
            fontSize="sm"
            color="#5B5B5B"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => push('/profile/network')}
          >
            Find your first connection
          </Text>
          </Stack>
          {/* <Text
            cursor="pointer"
            onClick={() => push('/profile/network')}
            textAlign={'center'}
            color="#3D5A80"
            fontSize={'12px'}
          >
            Manage connections
          </Text> */}
        </Stack>
      </>
    );


  return (
    // <Scrollable  {...props}>
    <Stack className={styles.hideNetwork} pl={4} {...props}>
      <Divider />

      <Stack pos="relative">
        <HStack zIndex={1} top="0"  p={3} pb={1} pos="sticky" >
          {/* <Box borderRadius="full" bg="white">
            <Avatar size="sm" src={'/my-circle-icon.png'} />
          </Box> */}
          <Heading
            flex={1}
            fontWeight="400"
            fontSize="md"
            color="brandPrimary.500"
          >
            Your Network
          </Heading>
        </HStack>
        {loading ? (
          <Stack>
            <UserCardSkeleton />
            <UserCardSkeleton />
          </Stack>
        ) : (
          ''
        )}
        {!loading ? (
          <Box
            className={styles.networkBox}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onScroll={handleScrollEnd}
            maxHeight={
              (isHovered || !isHovered) && sessionStorage.getItem('showMore')
                ? 'calc(100vh - 650px)'
                : (isHovered || !isHovered) &&
                  !sessionStorage.getItem('showMore')
                ? 'calc(100vh - 730px)'
                : 'calc(100vh - 245px)'
            }
          >
            <Stack zIndex={0} pos="relative" px={2} pb={4}>
              {sortedConnections
                 ?.filter?.((_, ind) => ind < connectionsLimit)
                ?.map((r, index) => {
                  const chatRoomId = ""
                  // findDirectRoomId(r?.matrix_user_id);
                  return (
                    <UserDetailsPopover
                      showPresence
                      user={r}
                      key={index}
                      chatRoomId={chatRoomId}
                    >
                      <HStack
                        // as={Link}
                        // href={'/p/' + r?.slug}
                        onClick={(e) => {
                          chatRoomId
                            ? dispatch(startChatWithUser(chatRoomId))
                            : startChatSingle?.(r);
                        }}
                        cursor="pointer"
                        // _hover={{

                        //   bg: '#E4E6EB',
                        //   // boxShadow: '0px 0px 100px rgba(0,0,0,0.1)',
                        // }}
                        _hover={{
                          bg: '#C6C6C6',
                          boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                        }}
                        borderRadius={5}
                        p={2}
                        key={index}
                      >
                        <UserPresenceDot user={r}>
                          <Avatar
                            pos="relative"
                            name={getUserFullName(r)}
                            src={getUserProfilePic(r)}
                          />
                        </UserPresenceDot>
                        <Stack spacing={0}>
                          <Text>{getUserFullName(r)}</Text>
                          <Text fontSize="xs">{getUserPosition(r)}</Text>
                        </Stack>
                      </HStack>
                    </UserDetailsPopover>
                  );
                })}
              {/* {connections.length > 5 && <Button variant="transparent" rightIcon={<ChevronDown />} onClick={() => push('/profile/network')}>
                Show more
              </Button>} */}
            </Stack>
          </Box>
        ) : (
          ''
        )}
      </Stack>
    </Stack>
  );
};
