/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { MyBridgeLogoMain } from '@mybridge/icons/MyBridgeLogo';
import { SearchIcon } from '@mybridge/icons/Search';
import {
  Avatar,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Spinner,
  Text,
} from '@mybridge/ui';
import { Input, InputGroup, InputLeftElement } from '@mybridge/ui/input';
import {
  Box,
  Circle,
  HStack,
  Spacer,
  Stack,
  VStack,
} from '@mybridge/ui/layout';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchContext } from 'v4/snippets/search/context';
import { AuthHeader } from '../auth/auth-header';
import { PrependPageHeadTitle } from '../custom-head';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import {
  CalendarIcon,
  CalendarNewIcon,
  DashboardLayoutIcon,
  DiskIcon,
  DiskNewIcon,
  EventClockIcon,
  FolderIcon,
  GroupIcon,
  HomeIcon,
  LayoutModeIcon,
  MyBridgeCircleIcon,
  SimpleCloseIcon,
  UsersIconColored,
} from '@mybridge/icons';
import { isMobile, isTablet } from 'react-device-detect';
import { useDisclosure } from '@mybridge/ui';
import { ProfileMenu } from '../auth/auth-header/profile-menu';
import MobileDrawerMenu from '../auth/mobile-drawer-menu';
import { logout, setChatLoggedIn } from 'v4/store/actions/auth.actions';
import {
  getRecentSearch,
  deleteRecentSearchID,
} from 'v4/store/actions/global.data.actions';
import RecentSearchListBox from 'v4/components/header/recent-search-box';
import { JobsListingSearchBar } from '../jobs/search-bar';
import { JobsListingContext } from '../jobs/context';
import NotificationDropdown from '../auth/auth-header/notifications';
import ShareLinkModalPublic from '../../pages/appointment/[slug]/share-link-modal'
import { checkAppointmentSlug } from 'v4/store/actions/calendar.actions';
import { getUserFullName } from 'v4/lib/commons';
import { css } from '@chakra-ui/react';
import { setDashboardClicked, toggleDashboardClicked } from 'v4/store/slices/dashboardAciveSlice';
import { setNotificationClicked } from 'v4/store/slices/notificationActiveSlice';
const Header = (props) => {
  const { loggedIn, pageLoaded } = useSelector((state) => state.user);
  const { globalNotifCount } = useSelector((state) => state.notifications);
  const { loading } = useSelector((state) => state.userProfile);
  const { recentSearches, loading: searchLoading } = useSelector(
    (state) => state.globalData
  );
  const { isPublicAppointment } = useSelector((state) => state.calendar);
  const { search, setSearch, setActiveTab } = useContext(SearchContext);
  const [openShareModal,setOpenShareModal]=useState(false)
  const [getAccountInfo, setGetAccountInfo] = useState([]);
  const dispatch = useDispatch();
  const { push, pathname, query } = useRouter();
  const isDiskPage = pathname?.includes('profile/disk');
  const sideDrawerDisc = useDisclosure();
  const searchBoxDisc = useDisclosure();
  const inputRef = useRef(null);
  const [newSearch, setNewSearch] = useState([]);
  const isJobsPage = pathname?.includes('jobs');
  const isAppointmentPublicPage = pathname?.includes('appointment');
  const [userInfo, setUserInfo] = useState([]);
  const [className, setClassName] = useState('hstack_large'); 
  const [inputField,setInputField]=useState("");
  const [tempSearch, setTempSearch] = useState("");
 
  const isPublicPage=pathname==='/g/people'||pathname==='/g/jobs'||pathname==='/g/pages'||pathname==='/g/teams'||pathname==='/g/events'||pathname==='/g/articles'||(pathname==='/'&&!loggedIn)
  const isProfile=pathname==='/profile/me'||pathname==='/profile/about'||pathname==='/profile/photos'||pathname==='/profile/videos'
  useEffect(() => {
    let array = recentSearches?.results;
    let temp = [];
    let newArray = array?.filter((ele) => {
      if (!temp.includes(ele.query) && temp.length < 5) {
        temp.push(ele.query);
        return true;
      } else {
        return false;
      }
    });
    setNewSearch(newArray);
  }, [recentSearches]);

  const isDashboardClicked = useSelector(
    (state) => state.dashboardActive.isDashboardClicked
  );

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      const val = e?.target?.value;
      const formattedQuery = val.trim().replace(/\s+/g, ' ');
      console.log("formattedQuery",formattedQuery);
      setSearch(formattedQuery);
      if (val.length > 2) {
        setActiveTab?.(val?.length ? 'all' : 'posts');
        if (val?.length) {
          searchBoxDisc.onClose();
          push('/g/?query=' + formattedQuery);
        } else {
          push('/');
        }
      }
    }
  };
  

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) { // Enter key
      const formattedQuery = tempSearch.trim().replace(/\s+/g, ' ');
      console.log("API triggered with query:", formattedQuery);
      setSearch(formattedQuery); // Update the actual search state
      if (formattedQuery.length > 2) {
        setActiveTab?.(formattedQuery.length ? 'all' : 'posts');
        searchBoxDisc.onClose();
        push('/g/?query=' + formattedQuery);
      } else {
        push('/');
      }
    }
  };
  
  const handleInputChange = (e) => {
    setTempSearch(e.target.value); // Update tempSearch for real-time input
  };

  useEffect(() => {
    if (query.query) {
      const formattedQuery = query.query.trim().replace(/\s+/g, ' ');
      setSearch(formattedQuery); // Update the actual search state
      setTempSearch(formattedQuery); // Update the temporary input state
    }
  }, [query.query]);

  const handleRecentSearch = (e) => {
    const val = e;
    setSearch(val);
    setActiveTab?.(val?.length ? 'all' : 'posts');
    if (val?.length) {
      push('/g/?query=' + e);
    } else {
      push('/');
    }
  };


  const updateClassName = () => {
    const zoomLevel = window.devicePixelRatio;
console.log(zoomLevel,"zoomLevelzoomLevel");
    if (loggedIn&&zoomLevel >= 1.35) {
      setClassName('hstack_small');
    } else if (loggedIn&&zoomLevel >= 1.1) {
      setClassName('hstack_medium');
    } else {
      if(loggedIn){
        setClassName('hstack_large');

      }
    }
  };


  const updateClassNameLoggedout = () => {
    const zoomLevel = window.devicePixelRatio;
console.log(zoomLevel,"zoomLevelzoomLevel");
    if (!loggedIn&&zoomLevel >= 1.35) {
      setClassName('hstack_small_loggedOut');
    } else if (!loggedIn&&zoomLevel >= 1.1) {
      setClassName('hstack_medium_loggedOut');
    } else {
     
        setClassName('hstack_large_loggedOut');

  
    }
  };

  useEffect(() => {
if(loggedIn){

  updateClassName();
  window.addEventListener('resize', updateClassName);
  return () => {
    window.removeEventListener('resize', updateClassName);
  };
}else if (!loggedIn){
  

  updateClassNameLoggedout();
  window.addEventListener('resize', updateClassNameLoggedout);
  return () => {
    window.removeEventListener('resize', updateClassNameLoggedout);
  };
}
    // Event listener for window focus
    const handleWindowFocus = () => {
      // Check if access token exists in localStorage
      const accessToken = localStorage.getItem('access');

      if (accessToken) {
        // Parse the token to get the expiration time
        const { exp } = JSON.parse(atob(accessToken.split('.')[1]));

        // Check if the token is expired
        const isTokenExpired = Date.now() >= exp * 1000;
        if (isTokenExpired) {
          console.log('Access token is expired. Redirect to login page.');
          dispatch(logout());
          push('/');
        } else {
          console.log('Access token is still valid.');
        }
        // } else {
        //   dispatch(logout());
        //   push('/');
      }
    };

    // Attach the event listener to the window focus event
    window.addEventListener('focus', handleWindowFocus);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [loggedIn]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (recentSearches?.count === 0 && loggedIn) dispatch(getRecentSearch());
  }, [loggedIn]);

  const handleRecentDelete = () => {
    const payload = { id: 'all' };
    dispatch(deleteRecentSearchID(payload)).then((res) => {
      if (res) dispatch(getRecentSearch());
    });
  };

  const handleCloseShareLinkModal=()=>{
    setOpenShareModal(false)
  }


  useEffect(()=>{
    if(!isAppointmentPublicPage||!query?.slug)return

    dispatch(checkAppointmentSlug({ search: query.slug })).then((res) => {
      console.log(res, 'ress');

      // if (res.payload.results.length > 0) {
        setGetAccountInfo(res.payload.results?.[0]);
      // }
    });
  },[query?.slug])

  const handleStopPropogation = () => {
    dispatch(setDashboardClicked(false));
    dispatch(setNotificationClicked(false));
  }

  return (<>
  {(
  <>
  <PrependPageHeadTitle>
    {loggedIn && globalNotifCount ? `(${globalNotifCount})` : ''}
  </PrependPageHeadTitle>

  {!!isAppointmentPublicPage && !!isPublicAppointment && (
    <header style={{ zIndex: 999  ,width: isMobile ? "100%" : "100%"}} className={styles.header}>
      <Stack
        pos="relative"
        w="100%"
        bg="white"
        boxShadow="0px 2px 10px 0px #00000033"
        borderBottom={'1px solid #ddd'}
        // zIndex={9999999}
      >
        <HStack py={4} px={36}>
          {!!getAccountInfo?.first_name && (
            <HStack
              cursor="pointer"
              onClick={() => {
                push(`/p/${getAccountInfo.slug}`);
              }}
            >
              {/* <Avatar color="white" name={getAccountInfo?.first_name+" "+getAccountInfo?.last_name ?? " "} size="sm" src={''} />
        <Text>{getAccountInfo?.first_name+" "+getAccountInfo?.last_name ?? " "}</Text> */}
              <HStack p={3} _hover={{ bg: '#F2F2F2' }} cursor="pointer">
                <Avatar
                  src={getAccountInfo?.profile_pic}
                  name={`${getAccountInfo?.first_name} ${getAccountInfo?.last_name}`}
                  size="md"
                />
                <Text
                  fontSize="20px"
                  fontWeight="600"
                  color="brandPrimary.500"
                >
                  {getUserFullName(getAccountInfo)}{' '}
                  {getAccountInfo?.pronouns
                    ? `(${getAccountInfo?.pronouns})`
                    : `(She/Her)`}
                </Text>
              </HStack>
            </HStack>
          )}

          <Spacer />
          {!!getAccountInfo?.appointment_profile && (
            <Button
              onClick={() => {
                setOpenShareModal(true);
              }}
              fontSize="15px"
              bg="#3D5A80"
              color="#FFFFFF"
              variant="solid"
            >
              Share
            </Button>
          )}
        </HStack>
      </Stack>
    </header>
  )}
  {!isAppointmentPublicPage && (
    <header className={styles.header} style={{ zIndex: 999  ,width: isMobile&&isProfile?'100%':isMobile&&!isProfile ? "unset" : "100%"}}>
      <Stack
        pos="relative"
        w="100%"
        bg="white"
        // shadow={[0, 'md']}
        borderBottom={['1px solid #ddd', 0]}
      >
      <Stack  onClick={handleStopPropogation}>
        <HStack
          // w={!isMobile ? ['1020px','1283px', '1280px'] : ''}
          className={isPublicPage? className:''}
          // maxW="1366px"
          p={!isMobile ? ['5px 10px 0', 2] : ''}
          pl={"0px !important"}
          justifyContent="space-around"
          margin={!isMobile?"auto": ''}
          // marginLeft={!isMobile ? '20px' : ''}


        >
          <HStack
            pl={loggedIn ? ['0px', '20px','0px'] : '0px'}
            // mr={3}
            // w={[
            //   'auto',
            //   'auto',
            //   // loggedIn ? (isJobsPage ? 'auto' : '9%') : '650px',
            // ]}
            // className={loggedIn? styles.paddingResponsive: styles.paddingResponsivelogout}
          className={loggedIn ?  styles.paddingResponsive : styles.paddingResponsivelogout }

          >
            <Box>
              {isMobile || isTablet ? (
                <MyBridgeCircleIcon
                  width="44px"
                  onClick={() => sideDrawerDisc.onOpen()}
                />
              ) : (
                <Link href="/">
                  {/* <MyBridgeLogoMain width={isMobile ? '30px' : '44px'} /> */}

                  <MyBridgeCircleIcon
                    width={isMobile ? '30px' : '49px'}
                    // onClick={() => sideDrawerDisc.onOpen()}
                  />
                </Link>
              )}
            </Box>
          </HStack>
          <HStack
            pl={[0, 0, '0px']}
            className={isMobile&&!loggedIn ? styles?.rightResponsiveMoblie :isMobile&&loggedIn?styles?.rightResponsiveMoblieLogedIn:loggedIn ? styles.rightResponsive: styles.rightResponsiveLogout}
            flex={'none'}
          >
            <Box w={!isMobile&&!loggedIn ? '580px':isMobile&&!setChatLoggedIn ? "280px": !isMobile && isJobsPage ?'580px':isMobile?'80vw':'360px'}>
              {!isMobile && isJobsPage ? (
                <JobsListingSearchBar />
              ) : recentSearches &&
                recentSearches?.count > 0 &&
                loggedIn ? (
                <>
                  {(!isMobile && !isTablet) || searchBoxDisc?.isOpen ? (
                    <Popover isOpen={searchBoxDisc.isOpen} onClose={searchBoxDisc.onClose}>
                      <PopoverTrigger>
                        <InputGroup>
                          <InputLeftElement
                            width={['30px', '48px']}
                            height={['30px', '48px']}
                          >
                            <SearchIcon
                              width={isMobile ? '14px' : '18px'}
                            />
                          </InputLeftElement>
                          <Input
                            pl={10}
                            bg="brandGray.500"
                            border="none"
                            borderRadius="full"
                            value={tempSearch} // Controlled by tempSearch
                            onChange={handleInputChange} // Updates tempSearch
                            onClick={searchBoxDisc.onOpen}
                            placeholder={
                              loggedIn
                                ? 'Search...'
                                : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                            }
                            onKeyDown={handleSearchKeyDown} // API call on Enter
                            height={['32px', '48px']}
                            className={styles.srarchResponsive}
                          />
                        </InputGroup>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent top={"-2px"} w={!isMobile&&!loggedIn ? '580px':isMobile&&!loggedIn ? "280px": '360px'} className={styles.searchPopUp}>
                          <PopoverBody
                            style={{
                              outline: 'none',
                              borderRadius: '80px',
                              padding: '0',
                            }}
                          >
                            <InputGroup>
                              <InputLeftElement
                                width={['30px', '48px']}
                                height={['30px', '48px']}
                              >
                                <SearchIcon
                                  width={isMobile ? '14px' : '18px'}
                                />
                              </InputLeftElement>
                              <Input
                                pl={10}
                                bg="#FFFFFF"
                                border="none"
                                borderRadius="full"
                                ref={inputRef}
                                value={tempSearch} // Controlled by tempSearch
                                onChange={handleInputChange} // Updates tempSearch
                                placeholder={
                                  loggedIn
                                    ? 'Search...'
                                    : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                                }
                                onKeyDown={handleSearchKeyDown} // API call on Enter
                                height={['32px', '48px']}
                              />

                            </InputGroup>
                            <Box className={styles.searchPopUpBottomLine} />
                            <VStack
                              align="stretch"
                              className={styles.searchGroup}
                            >
                              <HStack justifyContent="space-between">
                                <Text
                                  fontSize="14px"
                                  color="black"
                                  fontWeight="600"
                                >
                                  Recent
                                </Text>
                                <Button
                                  variant="transparent"
                                  size="md"
                                  p={0}
                                  style={{
                                    fontWeight: '500',
                                    color: '#3D5A80',
                                  }}
                                  onClick={() => handleRecentDelete()}
                                >
                                  Clear Searches
                                </Button>
                              </HStack>
                              {!searchLoading ? (
                                recentSearches &&
                                newSearch?.map((recent, i) => (
                                  <RecentSearchListBox
                                    key={i}
                                    recent={recent}
                                    onClick={() => {
                                      handleRecentSearch(recent?.query)
                                      setTempSearch(recent?.query)
                                      searchBoxDisc.onClose()
                                    }}
                                    cursor="pointer"
                                  />
                                ))
                              ) : (
                                <HStack justifyContent="center">
                                  <Spinner />
                                </HStack>
                              )}
                            </VStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {!isJobsPage&&(<InputGroup>
                    <InputLeftElement
                      width={['30px', '48px']}
                      height={['30px', '48px']}
                    >
                      <SearchIcon width={isMobile ? '14px' : '18px'} />
                    </InputLeftElement>
                    <Input
                      pl={10}
                      bg="brandGray.50"
                      border="none"
                      ref={inputRef}
                      borderRadius="full"
                      defaultValue={search}
                      placeholder={
                        loggedIn
                          ? 'Search...'
                          : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                      }
                      onKeyDown={handleSearch}
                      height={['32px', '48px']}
                    />
                  </InputGroup>)

                  }
                  {
                     isMobile&&isJobsPage&&(
                      <JobsListingSearchBar />
                    )
                  }
                </>
              )}
            </Box>
          </HStack>

          <HStack
          className={loggedIn ?  styles.notJobPage : styles.logoutRightStack }
            pl={loggedIn ? ['0px', '0px'] : '0px'}
            // w={[
            //   'auto',
            //   'auto',
            //   loggedIn ? (isJobsPage ? 'auto' : '315px') : '650px',
            // ]}
            
            display="flex"
            justifyContent="end"
          >
            <AuthHeader
              onSearchClose={() => searchBoxDisc?.onClose()}
              onSearchOpen={() => searchBoxDisc?.onToggle()}
            />
          </HStack>
        </HStack>
      </Stack>

        {loading || !pageLoaded ? (
          <Progress
            top={0}
            left={0}
            right={0}
            position="absolute"
            isIndeterminate
            h={1}
          />
        ) : (
          ''
        )}
      </Stack>
      {(isMobile || isTablet) && loggedIn ? (
            <HStack
              onClick={() => {
                dispatch(setDashboardClicked(false));
                dispatch(setNotificationClicked(false));
              }}
              bg={"white"}
              borderBottom="1px solid #ddd"
              justifyContent="space-between"
              p="2% 0%"
            >
              <IconButton
                icon={<HomeIcon width="26" height="26" color={pathname === "/" ? "#4170A4" : "#5B5B5B"} />}
                variant="transparent"
                onClick={() => {
                  push('/')
                  dispatch(setDashboardClicked(false));
                  dispatch(setNotificationClicked(false));
                }}
              />
              <IconButton
                icon={<GroupIcon width="26" height="26" color={pathname === "/profile/network" ? "#4170A4" : "#5B5B5B"} />}
                variant="transparent"
                onClick={() => {
                  push('/profile/network')
                  dispatch(setDashboardClicked(false));
                  dispatch(setNotificationClicked(false));
                }}
              />
              <IconButton
                icon={<CalendarIcon width="24" height="24" color={pathname === "/profile/calendar" ? "#4170A4" : "#5B5B5B"} />}
                variant="transparent"
                // onClick={() => calendarDisc.onOpen()}
                onClick={() => {
                  push('/profile/calendar')
                  dispatch(setDashboardClicked(false));
                  dispatch(setNotificationClicked(false));
                }}
              />
              <IconButton
                icon={<FolderIcon width="26" height="26" color={pathname === "/profile/disk" ? "#4170A4" : "#5B5B5B"} />}
                variant="transparent"
                onClick={() => {
                  push('/profile/disk')
                  dispatch(setDashboardClicked(false));
                  dispatch(setNotificationClicked(false));
                }}
              />
              {isMobile || isTablet ? <NotificationDropdown /> : <></>}

              <ProfileMenu>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(toggleDashboardClicked());
                    dispatch(setNotificationClicked(false));
                  }}
                  icon={<DashboardLayoutIcon width="26" height="26"  strokeColors={isDashboardClicked ? ['#4170A4', '#4170A4', '#4170A4', '#4170A4'] : ['#5B5B5B', '#5B5B5B', '#5B5B5B', '#5B5B5B']}/>}
                  variant="transparent"
                />
              </ProfileMenu>
            </HStack>
      ) : (
        <></>
      )}

      <MobileDrawerMenu
        onOpen={sideDrawerDisc?.isOpen}
        onClose={() => sideDrawerDisc?.onClose()}
      />
    </header>
  )}
  <ShareLinkModalPublic
    isOpen={openShareModal}
    onClose={handleCloseShareLinkModal}
  />
</>
  )}
    </>
  );
};

Header.propTypes = {};

export default Header;
