/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import { useToast } from '@mybridge/ui/hooks';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Spinner } from '@mybridge/ui/spinner';
import { Text } from '@mybridge/ui/text';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSuggestLocationFields } from 'v4/components/auto-suggest-location';
import { MultiStepFormContext } from 'v4/components/multi-step-form/context';
import {
  getOnboardingData,
  setOnboardingData,
} from 'v4/store/actions/onboarding.actions';
export const UserOnboardingFormLocationStep = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { next, formData, setFields } = useContext(MultiStepFormContext);
  const { country, postal, city, state } = formData ?? {};
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const toast = useToast({
    position: 'top',
  });

  useEffect(() => {
    fetchData?.();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } =
        (
          await dispatch(
            getOnboardingData({
              step: 'profile',
              emailId: userProfileInfo?.email,
            })
          )
        )?.payload ?? {};
      const { country, state, city, postal_code } = data ?? {};
      setFields({ country, state, city, postal: postal_code });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveLocation = async () => {
    try {
      setLoading(true);
      const payload = {
        step: 'profile',
        emailId: userProfileInfo?.email,
        data: {
          country,
          state,
          city,
          postal_code: postal,
        },
      };
      const resp = await dispatch(setOnboardingData(payload));
      return true;
    } catch (e) {
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (location)=>{
    setFields(location)
  }

  const handleNext = async () => {
    const done = await saveLocation();
    if (done) {
      next?.();
    }
  };

  if (loading)
    return (
      <HStack>
        <Spinner />
      </HStack>
    );

  // console.warn(formData);

  return (
    <Stack>
      <Stack textAlign="center" alignItems="center">
        <Heading color="#3D5A80 !important" size="md">Welcome, {userProfileInfo?.first_name}!</Heading>
        <Text>
          First, let's find out where you live. This is the first step in
          creating your myProfile.
        </Text>
      </Stack>

      <form>
        <Stack spacing={4}>
          <AutoSuggestLocationFields
            countryWrapperProps={{ minW: 'full' }}
            cityStateWrapperProps={{ minW: 'full' }}
            postalWrapperProps={{ minW: 'full' }}
            regionWrapperProps={{ minW: 'full' }}
            value={formData}
            onChange={handleChange}
          />
          <Button fontWeight="600" borderRadius="30px" height="48px" variant="primary" onClick={handleNext}>
            Next
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
