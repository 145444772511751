/* eslint-disable @nx/enforce-module-boundaries */
import { TriangleDownIcon } from '@chakra-ui/icons';
import { ChevronDown, ChevronDownNew } from '@mybridge/icons';
import { useDisclosure } from '@mybridge/ui/hooks';
import { Input, InputGroup, InputRightElement } from '@mybridge/ui/input';
import { List, ListItem } from '@mybridge/ui/list';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { Spinner } from '@mybridge/ui/spinner';
import { useEffect, useRef, useState } from 'react';

export const CustomDropdown = ({
  value,
  defaultValue,
  onChange,
  clearOnSelect = false,
  allowCreate = true,
  children,
  keyName = 'name',
  onSearch,
  isLoading,
  field,
  data = [],
  inputGroupProps,
  inputRElementProp,
  inputProps,
  onEscape,
  placeHolder,
  dropDownAerrow,
  ...props
}) => {

  const inputRef = useRef();
  const containerRef = useRef();
  const [search, setSearch] = useState(clearOnSelect ? '' : value);
  const disc = useDisclosure();
  const [selected, setSelected] = useState();
  const tid = useRef(-1);

  const handleSelect = (item) => () => {
    onSelect(item);
  };

  const onSelect = (item) => {
    if (item) {
      setSelected(item);
      setSearch(clearOnSelect ? '' : item?.[keyName]);
      onSearch?.(clearOnSelect ? '' : item?.[keyName]);
      onChange(field ? item?.[field] : item);
      disc?.onClose?.();
    }
  };

  const onSearchChange = (val) => {
    setSearch(val);
    onSearch?.(val);
  };

  const onKeyDown = (e) => {
    if (e.keyCode !== 13 && e.keyCode !== 27) return;
    e.preventDefault?.();
    e.stopPropagation?.();
  };

  const onKeyUp = (e) => {
    e.preventDefault?.();
    e.stopPropagation?.();
    if (e.keyCode === 27) {
      inputRef.current.value = '';
      onEscape?.();
      return;
    }
    if (e.keyCode === 13) {
      onChange?.(field ? e?.target?.value : { [keyName]: e?.target?.value });
      inputRef.current.value = '';
      return;
    }
    disc?.onOpen?.();
  };

  // useEffect(() => {
  //   if (value !== search) {
  //     setSearch(value);
  //   }
  // }, [value]);

  return (
    <Popover
      {...disc}
      ref={containerRef}
      matchWidth={true}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <InputGroup {...(inputGroupProps ?? {})}>
          {/* {isLoading ? (
            <InputRightElement
              h={props?.variant === 'greyRounded' ? '36px' : '40px'}
            >
              <Spinner />
            </InputRightElement>
          ) : (
            <InputRightElement
              onClick={(e) => {
                onSearchChange(inputRef?.current?.value ?? '');
              }}
              h={props?.variant === 'greyRounded' ? '36px' : '40px'}
            >
              <ChevronDownNew width="24" height="24" />
            </InputRightElement>
          )} */}
          {dropDownAerrow && (
            <InputRightElement
              onClick={(e) => {
                onSearchChange(inputRef?.current?.value ?? '');
              }}
              h={props?.variant === 'greyRounded' ? '36px' : props?.variant === 'whiteInput' ? '60px' : '40px'}
            >
              <ChevronDownNew width="24" height="24" />
            </InputRightElement>
          )}
          
          {children ? (
            children?.({
              inputRef,
              ref: inputRef,
              value: search,
              disc,
              onChange: onSearchChange,
              onKeyUp,
              onKeyDown,
            })
          ) : (
            <Input
              placeHolder={placeHolder ? placeHolder : "select"}
              ref={inputRef}
              value={typeof(search)==='object'?search?.name:search}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onClick={(e) => disc?.onOpen?.()}
              onChange={(e) => onSearchChange(e.target.value)}
              {...props}
              {...inputProps}
            />
          )}
        </InputGroup>
      </PopoverTrigger>
      {disc?.isOpen && (search?.length > 0 || data?.length > 0) ? (
        <PopoverContent p={0} w="100%" maxH="200px" overflowY="auto">
          {!allowCreate && isLoading ? (
            ''
          ) : (
            <PopoverBody p={0}>
              <List>
                {allowCreate && search?.length > 0 ? (
                  <ListItem
                    _hover={{
                      bg: 'gray.100',
                    }}
                    px={4}
                    py={2}
                    onClick={handleSelect({ [keyName]: search })}
                  >
                    Create &quot;{search}&quot;
                  </ListItem>
                ) : (
                  ''
                )}
                {!isLoading && data?.length > 0
                  ? data
                      ?.filter?.((c) =>
                        c?.[keyName]
                          ?.toLowerCase?.()
                          ?.includes(search?.toLowerCase?.())
                      )
                      ?.map?.((c, cind) => (
                        <ListItem
                          key={cind}
                          _hover={{
                            bg: 'gray.100',
                          }}
                          px={4}
                          py={2}
                          onClick={handleSelect(c)}
                        >
                          {c?.[keyName]}
                        </ListItem>
                      ))
                  : ''}
              </List>
            </PopoverBody>
          )}
        </PopoverContent>
      ) : (
        ''
      )}
    </Popover>
  );
};
