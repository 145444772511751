/* eslint-disable @nx/enforce-module-boundaries */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconButton } from '@mybridge/ui/icon-button';
import { ChatIcon, NewGroup, ChatDefaultIcon } from '@mybridge/icons';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { HStack, Box, Circle } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import ConversationListCard from 'v4/snippets/chat/conversation-card';
import { Button } from '@mybridge/ui/button';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/hooks';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { Badge, Portal } from '@mybridge/ui';
import Link from 'next/link';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { isMobile } from 'react-device-detect';
import { Tooltip } from '@mybridge/ui';
import { setMainChatNotification } from 'v4/store/actions/chat.actions';
import { useRouter } from 'next/router';
import { getUserRoomList } from 'v4/store/actions/global.data.actions';
import { io } from 'socket.io-client';
const ConversationList = (props) => {
  const dispatch = useDispatch();
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const {
    matrixClient,
    setupMatrixClient,
    teamsChats,
    newChatDisc,
    unreadCount,
    setUnreadCount,
  } = useContext(MatrixContext);
  const chatMenuDisc = useDisclosure();
  const [chatList, setChatList] = useState([]);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const router = useRouter();
  const [showCount, setShowCount] = useState(false);
  const [loading, setLoading] = useState(true);
  const TOKEN_NAME = process.env.BASE_TOKEN_NAME;
  const socketRef = useRef(null);
  const SOCKET_SERVER_URL = 'wss://api.bybk.org';
  const accessToken =
    typeof window !== 'undefined' && localStorage.getItem(TOKEN_NAME);
  // Initialize socket connection
  useEffect(() => {
    socketRef.current = io(SOCKET_SERVER_URL, {
      extraHeaders: {
        token: `${accessToken}`,
      },
    });

    socketRef.current.on('connect', () => {
      console.log('Socket connected!');
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [accessToken]);
  useEffect(() => {
    // Handle incoming chat events
    const handleChatEvent = (msg) => {
      console.log('Received chat event:', msg);

      // Assuming the message has a property indicating a notification
      if (msg) {
        setTotalNotificationCount(
          (prevCount) => prevCount + 1
        );
      }
    };

    // Set up the socket event listener
    socketRef?.current?.on('chat_event', handleChatEvent);

    // Cleanup function to remove the listener
    return () => {
      socketRef?.current?.off('chat_event', handleChatEvent);
    };
  }, [socketRef]);

  useEffect(() => {
    if (matrixClient) {
      const roomList = matrixClient.getRooms();
      // Sort the rooms based on the most recent activity
      const sortedRooms = roomList.sort((a, b) => {
        const lastEventA = a.timeline[a.timeline.length - 1];
        const lastEventB = b.timeline[b.timeline.length - 1];
        // console.log('Room Names', a.name);
        if (lastEventA && lastEventB) {
          return lastEventB.getTs() - lastEventA.getTs();
        }
      });

      // Filter rooms based on conditions (more than one member and contains messages)
      const filteredRooms = sortedRooms.filter((room) => {
        // Check if the room has more than one member
        const members = room.getJoinedMembers();
        const timelineEvents = room.getLiveTimeline().getEvents();
        const lastMessageEvent = timelineEvents.find((event) => {
          return event.getType() === 'm.room.message';
        });
        // console.log('lastMessageEvent', lastMessageEvent);
        // Check if the room has messages
        return members?.length > 1 && lastMessageEvent;
      });
      setChatList(filteredRooms);

      const totalUnreadCounts = filteredRooms.reduce((count, room) => {
        return count + room.getUnreadNotificationCount('total');
      }, 0);
      // setUnreadCount(totalUnreadCounts);
      dispatch(setMainChatNotification(totalUnreadCounts));
      // setChatList(_.toArray(conversationList));
      setLoading(false);
    }
  }, [matrixClient, chatMenuDisc.isOpen]);

  // useEffect(() => {
  //   if (matrixClient === null) {
  //     setupMatrixClient();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (currentMeetingState) {
  //     dispatch(setMeetingWindowed());
  //   }
  // }, [chatMenuDisc.isOpen]);

  // useEffect(() => {
  //   if (unreadCount > 0) {
  //     setShowCount(true);
  //   }
  // }, [unreadCount]);

  const fetchRoomList = async (searchQuery = '') => {
    dispatch(getUserRoomList(searchQuery)).then((res) => {
      console.log(res, 'resssssssss');
      const rooms = res?.payload?.rooms || [];
      const totalNotifications = rooms?.reduce(
        (sum, room) => sum + (room?.notification_count || 0),
        0
      );
      setTotalNotificationCount(totalNotifications);
    });
  };

  useEffect(() => {
    fetchRoomList();
  }, []);
  return (
    <>
      <Box>
        <Popover placement="bottom-end">
          <PopoverTrigger onClick={() => router.push(`/profile/message`)}>
            {!isMobile ? (
              <IconButton
                bg="transparent"
                // _hover={{
                //   backgroundColor: props?.isAuth !=="Auth" && "transparent",
                // }}
                position="relative"
                // px={[1, 2, 8]}
                size={isMobile ? 'sm' : 'md'}
                // variant="homeHeaderButtons"
                width="60px"
              >
                <>
                  {totalNotificationCount > 0 ? (
                    <Badge
                      position="absolute"
                      bg="brandRed.500"
                      color="white"
                      borderRadius="100%"
                      top="0"
                      minW="25px"
                      border="2px solid #ffffff"
                      p="3px 5px"
                      left="50%"
                      zIndex="3"
                    >
                      {totalNotificationCount}
                    </Badge>
                  ) : (
                    ''
                  )}
                  <Tooltip label="Messenger" bg="black" p={2}>
                    <Box
                      as="span"
                      onClick={() => router.push(`/profile/message`)}
                      _hover={{
                        backgroundColor: 'transparent',
                      }}
                    >
                      <ChatIcon
                        width={isMobile && !props.width ? '18' : props.width}
                        height={isMobile && !props.height ? '18' : props.height}
                      />
                    </Box>
                  </Tooltip>
                </>
              </IconButton>
            ) : (
              <IconButton
                position="relative"
                minW="32px"
                borderRadius="full"
                w="32px"
                h="32px"
                BoxShadow="0px 0px 10px 0px #00000029;0px 4px 5px 0px #0000000F inset;0px -5px 3px 0px #0000000F inset;"
                bg="#DEDEDE"
              >
                <>
                  {unreadCount > 0 ? (
                    <Badge
                      position="absolute"
                      bg="brandRed.500"
                      color="white"
                      borderRadius="100%"
                      top="0"
                      minW="25px"
                      border="2px solid #ffffff"
                      p="3px 5px"
                      left="50%"
                      zIndex="3"
                    >
                      {unreadCount}
                    </Badge>
                  ) : (
                    ''
                  )}
                  <Tooltip label="Messenger" bg="black" p={2}>
                    <Box as="span">
                      <ChatIcon
                        width={isMobile ? '18' : '35'}
                        height={isMobile ? '18' : '35'}
                      />
                    </Box>
                  </Tooltip>
                </>
              </IconButton>
            )}
          </PopoverTrigger>
        </Popover>
      </Box>
    </>
  );
};

export default ConversationList;
