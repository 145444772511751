/* eslint-disable @nx/enforce-module-boundaries */
import {
  AppointmentsIcon,
  CalendarIcon,
  CalendarNewIcon,
  MyCalendarSideBar,
  EventIcon,
  RecordingIcon,
  MyEventsIcon,
  UpcomingMeetingsIcon,
  EventIconColored,
  JoinMeetingColoredIcon,
  JoinMeetingIcon,
  RecordVideoCameraIcon,
  ScheduleIcon,
  AppointmentScheduleIcon,
} from '@mybridge/icons';
import {
  Box,
  Button,
  Card,
  Divider,
  Heading,
  VStack,
  Stack,
  Circle,
} from '@mybridge/ui';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateMeetingId,
  setJoinMeetNow,
  setMeetNow,
} from 'v4/store/actions/meeting.actions';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { Badge, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
const CalendarSidebar = ({ isDashboard, ...props }) => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { unreadMeetingCount } = useSelector((state) => state.globalData);
  const [meetNowModal, setMeetNowModal] = useState(false);
  const {generatedMeetingId} = useSelector((state) => state?.meeting);
  const [domain, setDomain] = useState('');
  const [copyText, setCopyText] = useState(<CopyIcon height={"18px"} width={"18px"}/>)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDomain(window.location.origin+`/meet/${generatedMeetingId}`);
    }
  }, [domain, generatedMeetingId]);
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(domain).then(() => {
      setCopyText('Copied');
      setTimeout(() => setCopyText(<CopyIcon height={"18px"} width={"18px"}/>), 5000);
    });
  };
  return (
    <>
      {isMobile ? (
        <>
          <Card
            _hover={{ bg: 'gray.200' }}
            variant="lightBgCard"
            p={2}
            w="100%"
            mt="4%"
          >
            <Button
              onClick={(e) => dispatch(setMeetNow(true))}
              variant="transparent"
              leftIcon={<EventIconColored width="20" height="20" />}
              justifyContent="flex-start"
            >
              Meet Now
            </Button>
          </Card>
          <Card _hover={{ bg: 'gray.200' }} variant="lightBgCard" p={2}  mt="4%">
            <Button
              onClick={(e) => dispatch(setJoinMeetNow(true))}
              variant="transparent"
              leftIcon={<JoinMeetingIcon />}
              justifyContent="flex-start"
            >
              Join a Meeting
            </Button>
          </Card>
          <Card _hover={{ bg: 'gray.200' }} variant="lightBgCard" p={2}  my="4%">
            <Link href="/profile/calendar/schedule">
              <Button
                variant="transparent"
                leftIcon={<ScheduleIcon width="27" height="27" />}
                justifyContent="flex-start"
              >
                Schedule a Meeting
              </Button>
            </Link>
          </Card>
        </>
      ) : (
        <>
        <Stack>
          {!isDashboard && (
            <VStack align="stretch" p={2}>
              {/* <Heading as="h4" color="#3B718D" fontSize="18px" mb={4}>
                Calendar
              </Heading> */}
              <Link href="/profile/calendar/">
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={pathname === '/profile/calendar' ? true : false}
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '400',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  myCalendar
                </Button>
              </Link>

              <Link href="/profile/calendar/calendar-listing">
                <Button
                  // onClick={(e) => dispatch(setJoinMeetNow(true))}
                  variant="ghost"
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/calendar-listing'
                      ? true
                      : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '400',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Upcoming meeting
                  {unreadMeetingCount > 0 && (
                    // <Circle
                    //   bg="brandRed.500"
                    //   color="white"
                    //   w="20px"
                    //   h="20px"
                    //   p="8px"
                    //   borderRadius="100%"
                    //   ml="8px"
                    // >
                    //   {unreadMeetingCount}
                    // </Circle>
                    <Badge
                      fontSize="12px"
                      bg="brandRed.500"
                      color="white"
                      borderRadius="100%"
                      w="25px"
                      h="25px"
                      border= '2px solid #ffffff'
                      p="3px 5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      ml="8px"
                      pt={"4px"}
                    >
                      {unreadMeetingCount}
                    </Badge>
                  )}
                </Button>
              </Link>

              <Link href="/profile/calendar/appoinments">
                <Button
                  // onClick={(e) => dispatch(setJoinMeetNow(true))}
                  variant="ghost"
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/appoinments' ? true : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '400',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Appointments
                </Button>
              </Link>
              <Link href="/myevents">
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  w="100%"
                  isActive={pathname === '/myevents' ? true : false}
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '400',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  myEvent
                </Button>
              </Link>
              <Link href="/profile/calendar/recordings">
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/recordings' ? true : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '400',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Recordings
                </Button>
              </Link>
              
            </VStack>
          )}
          {!isDashboard && <Divider />}
          <VStack align="stretch" p={2}>
            <Button
              // onClick={(e) => dispatch(setMeetNow(true))}
              onClick={() => {
                dispatch(generateMeetingId()).then((res) => {
                  if (res) setMeetNowModal(true);
                  // dispatch(setMeetNow(false));
                });
              }}
              variant="ghost"
              height="50px"
              paddingInlineStart={isDashboard ? '0px' : '20px'}
              leftIcon={
                isDashboard ? (
                  <Box
                    borderRadius="full"
                    bg="#D9D9D9"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="35px"
                    h="35px"
                  >
                    <EventIconColored width="20" height="20" />
                  </Box>
                ) : (
                  <EventIconColored width="20" height="20" />
                )
              }
              justifyContent="flex-start"
              fontWeight="400"
              color="#3D5A80"
              sx={{
                ':hover': {
                  backgroundColor: 'brandGray.500',
                  cursor: 'pointer',
                  // color: 'brandPrimary.500',
                  bg: '#C6C6C6',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  fontWeight: '400',
                  path: {
                    // fill: 'brandPrimary.500',
                  },
                },
              }}
            >
              New Meeting  
              {/* meet now menu */}
            </Button>
            <Button
              onClick={(e) => dispatch(setJoinMeetNow(true))}
              variant="ghost"
              height="50px"
              paddingInlineStart={isDashboard ? '0px' : '20px'}
              leftIcon={
                isDashboard ? (
                  <Box
                    borderRadius="full"
                    bg="#D9D9D9"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="35px"
                    h="35px"
                  >
                    <JoinMeetingColoredIcon width="20" height="20" />
                  </Box>
                ) : (
                  <JoinMeetingColoredIcon width="20" height="20" />
                )
              }
              justifyContent="flex-start"
              fontWeight="400"
              color="#3D5A80"
              sx={{
                ':hover': {
                  backgroundColor: 'brandGray.500',
                  cursor: 'pointer',
                  // color: 'brandPrimary.500',
                  fontWeight: '400',
                  bg: '#C6C6C6',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  path: {
                    // fill: 'brandPrimary.500',
                  },
                },
              }}
            >
              Join a Meeting
            </Button>
            <Link href="/profile/calendar/schedule">
              <Button
                variant="ghost"
                height="50px"
                paddingInlineStart={isDashboard ? '0px' : '20px'}
                leftIcon={
                  isDashboard ? (
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <CalendarNewIcon width="20" height="20" />
                    </Box>
                  ) : (
                    <CalendarNewIcon width="20" height="20" />
                  )
                }
                justifyContent="flex-start"
                fontWeight="400"
                color="#3D5A80"
                w="100%"
                isActive={
                  pathname === '/profile/calendar/[slug]' ? true : false
                }
                _active={{
                  backgroundColor: '#C6C6C6',
                  cursor: 'pointer',
                  color: '#3D5A80',
                  fontWeight: '400',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  // path: {
                  //   fill: 'brandPrimary.500',
                  // },
                }}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                    fontWeight: '400',
                    backgroundColor: '#C6C6C6',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    path: {
                      // fill: 'brandPrimary.500',
                    },
                  },
                }}
              >
                Schedule a Meeting
              </Button>
            </Link>
            <Link href="/profile/calendar/appoinments">
              <Button
                // onClick={(e) => dispatch(setJoinMeetNow(true))}
                variant="ghost"
                paddingInlineStart={isDashboard ? '0px' : '20px'}
                leftIcon={
                  isDashboard ? (
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <AppointmentScheduleIcon />
                    </Box>
                  ) : (
                    <AppointmentScheduleIcon />
                  )
                }
                justifyContent="flex-start"
                fontWeight="400"
                color="#3D5A80"
                mb={4}
                w="100%"
                isActive={
                  pathname === '/profile/calendar/appoinments' ? true : false
                }
                sx={{
                  ':hover': {
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '400',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  },
                }}
              >
                Appointment Schedule
              </Button>
            </Link>
          </VStack>
          </Stack>
        </>
      )}
      <Modal isOpen={meetNowModal} size={'xl'} isCentered onClose={() => setMeetNowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your New Meeting Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} display={'flex'} flexDirection={'column'} gap={"20px"}>
            <Text>Here is the link for your new meeting!</Text>
            <Text fontSize={"14px"}>Copy and share it with the people you're meeting with and be sure to save the link so you can access it again later.</Text>
            <Box display="flex" alignItems="center" gap="8px">
              <InputGroup>
                <Input value={domain} isReadOnly />
                <InputRightElement width="4.5rem">
                  <Button variant="none" h="1.75rem" size="sm" onClick={copyToClipboard} title="Copy to clipboard">
                    {/* <CopyIcon height={"18px"} width={"18px"}/> */} {copyText}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
          </ModalBody>
          <ModalFooter sx={{display: "flex", justifyContent: "start"}}>
            <Button 
              variant="primary" 
              borderRadius="30px" 
              onClick={(e) => {
                dispatch(setMeetNow(true));
                setMeetNowModal(false);
              }}
            >
              Join Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CalendarSidebar;
