import { Button, CloseButton, Spinner, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DeleteIcon2 } from '@mybridge/icons';
import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@mybridge/ui';
import axios from 'axios';
import { useRouter } from 'next/router';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from 'v4/snippets/global/context';
import { TeamsContext } from 'v4/snippets/teams/context';
import { getMyActivity, getUserProfileInfo } from 'v4/store/actions/user-profile.actions';
export const ImagePopup = forwardRef(({ src, onClose, ...props }, ref) => {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    teamDetails,
    createOrUpdateTeam,setChatTeamSlug
  } = useContext(TeamsContext);
  const { imageType } = useContext(GlobalContext);

  const handleRemove = async() => {
    const formData = new FormData();
    if(imageType === 'team-banner') {
    setIsLoading(true);
    const payload = {
      id: teamDetails?.id,
      banner_image:null,
    };
    await createOrUpdateTeam(payload).then((res)=>{
      setChatTeamSlug(teamDetails?.slug)
      setIsSuccess(true);
      setIsLoading(false);
      if (onClose) {
        onClose();
      }
    })
    }
    else if (imageType === 'team-profile') {
      setIsLoading(true);
      const payload = {
        id: teamDetails?.id,
        image_logo:null,
      };
      await createOrUpdateTeam(payload).then((res)=>{
        setChatTeamSlug(teamDetails?.slug)
        setIsSuccess(true);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      })

    } else if(imageType === 'profile-banner') {
      setIsLoading(true);
      formData.append('profile_banner_pic', new Blob(), '');
      axios.put(
        'https://api.bybk.org/api/v1/user/profile/info/',
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((response) => {
        setIsSuccess(true);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        console.error('Error removing photo:', error?.response?.data || error?.message);
      });
    } else {
      setIsLoading(true);
      formData.append('profile_pic', new Blob(), '');
      axios.put(
        'https://api.bybk.org/api/v1/user/profile/info/',
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((response) => {
        setIsSuccess(true);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        console.error('Error removing photo:', error?.response?.data || error?.message);
      });
    }
  };
  const closeSuccessModal = () => {
    setIsSuccess(false);
  };

  useEffect(() => {
    dispatch(getUserProfileInfo());
    dispatch(getMyActivity());
  }, [isSuccess, dispatch]); 

  return (
    <Portal>
      <Modal onClose={onClose} size="full" {...props}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>
        </ModalHeader> */}
          <ModalBody
            bgColor="blackAlpha.900"
            overflowY="auto"
            as={HStack}
            justifyContent="center"
            pos="relative"
          >
            <HStack pos="absolute" justifyContent="space-between" zIndex={2} left={0} right={0} top={0}>
              <Box display="flex" alignItems="center" gap="15px" p={2}>
                <Image src="/images/logoMb.png" w="8" h="8" />
              </Box>
              <Box marginRight={isMobile ? "15%" : "3%"}>
                {isLoading ? <Spinner size="lg" color='#fff'/> : <Button title='Remove Profile Pic' padding={0} variant="none" leftIcon={<DeleteIcon2 color="white" />} border={"transparent"} color={"white"} onClick={handleRemove}>Remove Photo</Button>}
              </Box>
              <ModalCloseButton color="#fff" />
            </HStack>
            <Image
              pos="relative"
              zIndex={1}
              bgColor="white"
              src={src}
              maxW="100%"
              maxH="99vh"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccess && (
        <Portal>
          <Modal isOpen={isSuccess}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader display="flex" justifyContent="space-between" alignItem="center">
                Deleted 
                <CloseButton variant={"none"} padding={0} border={0} onClick={closeSuccessModal} />
              </ModalHeader>
              <ModalBody>
                {`Your picture has been successfully removed`} 
              </ModalBody>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </Portal>
  );
});
