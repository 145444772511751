/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Card,
  Container,
  HStack,
  Heading,
  Scrollable,
  Stack,
  Text,
  VStack,
} from '@mybridge/ui';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MainCopyrightsText } from 'v4/components/copyrights-text';
import { NewUserPanel } from 'v4/snippets/dashboard/public-view/new-user-panel';
import { SearchContext } from 'v4/snippets/search/context';
import { SearchSidebar } from 'v4/snippets/search/sidebar';
import { DashboardCalendar } from 'v4/snippets/dashboard/calendar';
import CalendarSidebar from 'v4/snippets/calendar/calendar-sidebar';
import { isMobile } from 'react-device-detect';
import { myBridgeData } from 'v4/components/header/status-dropdown/constant';
import  styles from './index.module.scss'
import { useRouter } from 'next/router';

export const SearchLayout = ({ activeTab, children }) => {
  const {
    query,
    setActiveTab,
    companyPagesFilters,
    peopleFilters,
    postsFilters,
    teamsFilters,
    articlesFilters,
    eventsFilters,
    jobsFilters,
    setPeopleFilters,
    setPostsFilters,
    setTeamsFilters,
    setArticlesFilters,
    setEventsFilters,
    setJobsFilters,
    setCompanyPagesFilters,
    resetFilters,
    setResetFilters,
  } = useContext(SearchContext);

  const { loggedIn } = useSelector((s) => s.user);
  const [randomThreeMessages, setRandomThreeMessages] = useState([]);
  const { pathname } = useRouter();
  const isPublicPage=pathname==='/g/people'||pathname==='/g/jobs'||pathname==='/g/pages'||pathname==='/g/teams'||pathname==='/g/events'||pathname==='/g/articles'
  
  useEffect(() => {
    function getRandomThree(arr) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    }
    
    // Initialize with three random messages on mount
    setRandomThreeMessages(getRandomThree(myBridgeData));
  }, []);

  useEffect(() => {
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [activeTab]);

  // useEffect(() => {
  //   if (postsFilters?.date_posted || postsFilters?.posted_by || postsFilters?.sort_by || postsFilters?.from_member || postsFilters?.from_company != null) {
  //     setResetFilters(true)
  //   } else if (peopleFilters?.locations || peopleFilters?.current_company || peopleFilters?.connections != null) {
  //     setResetFilters(true)
  //   } else {
  //     setResetFilters(false)
  //   }
  // }, [postsFilters, peopleFilters])

  // useEffect(() => {
  //   if (peopleFilters?.locations || peopleFilters?.current_company || peopleFilters?.connections != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [peopleFilters])

  // useEffect(() => {
  //   if (companyPagesFilters?.location || companyPagesFilters?.industry || companyPagesFilters?.company_size != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [companyPagesFilters])

  // useEffect(() => {
  //   if (teamsFilters?.location || teamsFilters?.industry != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [teamsFilters])

  // useEffect(() => {
  //   if (articlesFilters?.posted_by || articlesFilters?.date_posted || articlesFilters?.sort_by || articlesFilters?.from_member || articlesFilters?.from_company != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [articlesFilters])

  // useEffect(() => {
  //   if (eventsFilters?.posted_by || eventsFilters?.date_posted || eventsFilters?.sort_by || eventsFilters?.from_member || eventsFilters?.from_company != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [eventsFilters])

  // useEffect(() => {
  //   if (jobsFilters?.date_posted || jobsFilters?.experience_level || jobsFilters?.company || jobsFilters?.job_type || jobsFilters?.remote != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [jobsFilters])

  const handleResetFilters = () => {
    setResetFilters(false);
    setPeopleFilters();
    setPostsFilters();
    setTeamsFilters();
    setArticlesFilters();
    setEventsFilters();
    setJobsFilters();
    setCompanyPagesFilters();
  };

  function getRandomThree(arr) {
    const shuffled = myBridgeData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  }

  // const randomThreeMessages = getRandomThree(myBridgeData);

  return (
    <>
      <HStack
        alignItems="flex-start"
        spacing={2}
        gap={4}
        flexDir={['column', 'column', 'row']}
        // border="1px solid red"
        // bg="white"
      >
        {!isMobile ? (
          <Box
            position={['initial', 'initial', 'sticky']}
            top="64px"
            // w={['full', 'full', '280px']}
            className={styles.leftZoomWidth}
            flexShrink={0}
            bg="white"
            // boxShadow="2px 0px 6px 0px #00000033"
          >
            <Scrollable>
              <Stack h="calc(100vh - 64px)">
                <Box flex={1}>
                  <Card p={[0, 0, 5, 6]} overflowY="auto" boxShadow="none">
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      {query?.query?.length ? (
                        <Heading
                          display={['none', 'none', 'block']}
                          size="sm"
                          color="brandPrimary.500"
                          fontWeight="400"
                        >
                          Search Results
                        </Heading>
                      ) : (
                        ''
                      )}
                      {(companyPagesFilters ||
                        peopleFilters ||
                        postsFilters ||
                        teamsFilters ||
                        articlesFilters ||
                        eventsFilters ||
                        jobsFilters) &&
                      resetFilters &&
                      Object.keys(
                        companyPagesFilters ||
                          peopleFilters ||
                          postsFilters ||
                          teamsFilters ||
                          articlesFilters ||
                          eventsFilters ||
                          jobsFilters
                      )?.length ? (
                        <Button
                          size="sm"
                          variant="transparent"
                          _hover={{ bg: '#E4E6EB' }}
                          onClick={() => handleResetFilters()}
                        >
                          Reset
                        </Button>
                      ) : (
                        ''
                      )}
                    </HStack>
                    <Stack
                      spacing={0}
                      gap={2}
                      flexDir={['row', 'row', 'column']}
                      maxW="50vh"
                    >
                      <SearchSidebar />
                    </Stack>
                  </Card>
                </Box>
                {/* <Box display={['none', 'none', 'block']} mb={3} pl={6}>
                  <MainCopyrightsText alignment="flex-start" />
                </Box> */}
              </Stack>
            </Scrollable>
          </Box>
        ) : (
          <Box
            position={['initial', 'initial', 'sticky']}
            top="108px"
            w={['full', 'full', '360px']}
            flexShrink={0}
            bg="white"
            boxShadow="2px 0px 6px 0px #00000033"
          >
            <SearchSidebar />
          </Box>
        )}

        <Box w={['100%', '500px', '']} flex={1} maxW="100%" px={[0, 0]} py={3}>
          <Container
            maxW="700px"
            className={styles.centerBox}
            // minW={"483px"}
            marginInlineStart={isMobile ? 'auto' : isPublicPage? '48px':'35px'}
            marginInlineEnd={'120px'}
            px={0}
          >
            {children}
          </Container>
        </Box>





        {!isMobile && (
          <Scrollable
            pos="sticky"
            minH="100vh"
            top="67px"
            // w={['100%', 'full', '335px']}
          >
            {/* <Stack
              spacing={2}
              // bg="white"
              display={['none', 'none', 'flex']}
              w={['100%', '100%', '330px']}
              // boxShadow="-2px 0px 6px 0px #00000033"
              // ms={2}
              h="100vh"
            > */}
              
        <Box
          // w={['100%', 'full', '335px']}
          flex={1}
          alignItems="center"
          justifyContent="center"
          // maxW="100%"
          // px={[2, 0]}
          // py={3}
        >
          <Container
            mt={5}
            //  border="1px solid #e0e0e094"
            marginInlineStart="0px"
            marginInlineEnd={'0px'}
            maxW="355px"
            h={'100vh'}
            px={0}

            mr={"20px"}
            className={styles.rightSidePannel}
          >
            {randomThreeMessages?.map((content) => (
              <Box
                bg="#ECECEC"
                boxShadow="0px 4px 4px 0px #00000040"
                mt={4}
                mx={2}
                minH="175px"
                border="1px solid #E4E6EB"
                borderRadius={'16px'}
              >
                <Stack p={2}>
                  <Text color="#617898" fontSize="19px">
                    {content?.heading}
                  </Text>
                  <VStack>
                    <Text color="#5B5B5B" fontSize="15px">
                      {content?.description}
                    </Text>
                  </VStack>
                </Stack>
              </Box>
            ))}

            <Box display={['none', 'none', 'block']} mt={'30px'}>
              <MainCopyrightsText alignment="center" />
            </Box>
          </Container>
        </Box>
            {/* </Stack> */}
          </Scrollable>
        )}













        {/* {!loggedIn && !isMobile ? (
          <Scrollable
            display={loggedIn ? 'none' : 'block'}
            position={['initial', 'initial', 'sticky']}
            top={20}
            w={['full', 'full', '25%']}
            flexShrink={0}
            as={Card}
          >
            <NewUserPanel />
          </Scrollable>
        ) : (
          <></>
        )} */}
        {/* {loggedIn && !isMobile ? (
          <Scrollable
            display={loggedIn ? 'block' : 'none'}
            position={['initial', 'initial', 'sticky']}
            top={20}
            w={['full', 'full', '25%']}
            flexShrink={0}
          >
            <Stack>
              <DashboardCalendar />
              <CalendarSidebar />
            </Stack>
          </Scrollable>
        ) : (
          <></>
        )} */}
      </HStack>
    </>
  );
};
