/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { Text } from '@mybridge/ui/text';
import { Heading } from '@mybridge/ui/heading';
import { Input } from '@mybridge/ui/input';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { Select } from '@mybridge/ui/select';
import { Spinner } from '@mybridge/ui/spinner';
import { Switch } from '@mybridge/ui/switch';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CompaniesDropdown } from 'v4/components/company-dropdown';
import { DateOfBirthInput } from 'v4/components/dob-input';
import { MultiStepFormContext } from 'v4/components/multi-step-form/context';
import { SchoolsDropdown } from 'v4/components/schools-dropdown';
import { EMPLOYMENT_CHOICES } from 'v4/lib/data';
import {
  getOnboardingData,
  setOnboardingData,
} from 'v4/store/actions/onboarding.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';

export const UserOnboardingFormCareerDetailsStep = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { next, back, setFields, formData, active } =
    useContext(MultiStepFormContext);
  const [mode, setMode] = useState(0); // 0:employee | 1:student
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const employmentTypes = useMemo(
    () => EMPLOYMENT_CHOICES,
    [EMPLOYMENT_CHOICES]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm({
    // defaultValues: formData?.careerDetails,
    values: formData?.careerDetails,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data, is_student } =
        (
          await dispatch(
            getOnboardingData({
              step: 'employment',
              emailId: userProfileInfo?.email,
            })
          )
        )?.payload ?? {};
      if (data) {
        console.log('data', data);
        const {
          name,
          start_year,
          end_year,
          title,
          eligibility,
          dob,
          employment_type,
          company_name,
        } = data ?? {};
        if (is_student) {
          setMode(1);
          setFields({
            careerDetails: {
              college: name,
              startYear: start_year,
              endYear: end_year,
              eligibility: userProfileInfo?.eligibility,
              // dob:{},
            },
          });
        } else {
          setFields({
            careerDetails: {
              title,
              employmentType: employment_type,
              companyName: company_name,
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const save = async (form) => {
    console.log(form,"fordddddddddddd");
    try {
      setLoading(true);
      const { day, month, year } = form?.dob ?? {};
      const payload = {
        step: 'employment',
        emailId: userProfileInfo?.email,
        data: {
          is_student: mode === 1,
          ...(mode === 1
            ? {
                name: form?.college,
                start_year: form?.startYear,
                end_year: form?.endYear,
                eligibility: form?.eligibility,
                dob: moment()
                  .set('D', day)
                  .set('M', month)
                  .set('y', year)
                  .format('YYYY-MM-DD'),
              }
            : {
                title: form?.title,
                employment_type: form?.employmentType,
                company_name: form?.companyName,
              }),
        },
      };
      const resp = await dispatch(setOnboardingData(payload));
      dispatch(getUserProfileInfo());
      return true;
    } catch (e) {
      setLoading(false);
      console.log(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onNext = async (form) => {
    setFields({ careerDetails: form });
    const done = await save(form);
    if (done) {
      next?.();
    }
  };

  const handleModeChange = () => {
    setFields({
      careerDetails: {},
    });
    reset?.();
    setMode(mode === 0 ? 1 : 0);
  };

  const yearOptions = (offset = 0) => {
    const year = moment().year() - offset;
    return Array.from(Array(50)).map((_, ind) => (
      <option key={ind} value={year - ind}>
        {year - ind}
      </option>
    ));
  };
  return (
    <Stack>
      <Stack py={2} textAlign="center" alignItems="center">
        {mode === 0 ? (
          <Heading color="#3D5A80" fontWeight="600" size="sm">
            Next, let's expand on your career details,
          </Heading>
        ) : (
          <Heading color="#3D5A80" fontWeight="600" size="sm">
            Next, let's establish your background.
          </Heading>
        )}
      </Stack>
      {loading ? (
        <HStack justifyContent="center">
          <Spinner />
        </HStack>
      ) : (
        <form onSubmit={handleSubmit(onNext)}>
          <Stack spacing={3}>
            {mode === 0 ? (
              <>
                <FormControl isInvalid={errors.title}>
                  <FormLabel 
                  fontSize='16px'
                  color="#5B5B5B"
                  lineHeight="20px"
                  fontWeight="400"
                >Most recent job title*</FormLabel>
                  <Input
                  fontSize='16px'
                  bg="#FFFFFF"
                  color="#5B5B5B"
                  lineHeight="20px"
                  fontWeight="400"  
                    {...register('title', { required: true })}
                    placeholder="e.g. Senior Manager"
                  />
                  <FormErrorMessage>
                    You need to provide a valid job title
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.employmentType}>
                  <FormLabel
                  fontSize='16px'
                  color="#5B5B5B"
                  lineHeight="20px"
                  fontWeight="400"
                  
                  >Employment type*</FormLabel>
                  <Select
                    
                      sx={{
                        bg:"#FFFFFF",
                        color: "#5B5B5B",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        "::placeholder": {
                          fontSize: "16px",
                          color: "#B1B1B1",

                        },
                      }}
                    
                    placeholder="Select One"
                    
                    {...register('employmentType', { required: true })}
                  >
                    {employmentTypes?.map((et, ind) => (
                      <option key={ind} value={et.value}>
                        {et.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    Please select a valid employment type
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.companyName}>
                  <FormLabel
                  fontSize='16px'
                  color="#5B5B5B"
                  lineHeight="20px"
                  fontWeight="400"
                  
                  >Most recent company*</FormLabel>
                  <Controller
                    render={({ field }) => (
                      <CompaniesDropdown
                        {...field}
                          bg="#FFFFFF"
                          fontSize='16px'
                          color="#5B5B5B"
                          lineHeight="20px"
                          fontWeight="400"
                        
                        placeholder="Type to search..."
                        onChange={(item) => field?.onChange?.(item?.name)}
                      />
                    )}
                    name="companyName"
                    control={control}
                    rules={{ required: true }}
                  />

                  <FormErrorMessage>
                    Please choose your most recent company name
                  </FormErrorMessage>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl isInvalid={errors.college}>
                  <FormLabel
                   fontFamily="Inter"
                   fontSize='16px'
                   color="#5B5B5B"
                   lineHeight="16px"
                   fontWeight="400"
                  >School or College/University*</FormLabel>
                  <Controller
                    render={({ field }) => (
                      <SchoolsDropdown 
                      sx={{
                        bg:"#FFFFFF",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        color: "#333",
                        "::placeholder": {
                          fontSize: "16px",
                          color: "#B1B1B1",

                        },
                      }}
                      placeholder="Search..." {...field} />
                    )}
                    name="college"
                    control={control}
                  />
                  <FormErrorMessage>
                    You need to provide a valid School or College/University
                  </FormErrorMessage>
                </FormControl>
                <HStack>
                  <FormControl isInvalid={errors.startYear}>
                    <FormLabel
                     fontFamily="Inter"
                     fontSize='16px'
                     color="#5B5B5B"
                     lineHeight="16px"
                     fontWeight="400"
                    >Start year*</FormLabel>
                    <Select
                      {...register('startYear', { required: true })}
                      sx={{
                        bg:"#FFFFFF",
                        color: "#5B5B5B",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        "::placeholder": {
                          fontSize: "16px",
                          color: "#5B5B5B",

                        },
                      }}
                      placeholder="Select"
                    >
                      {yearOptions(2)}
                    </Select>
                    <FormErrorMessage>
                      Please select a valid date
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.endYear}>
                    <FormLabel
                     fontFamily="Inter"
                     fontSize='16px'
                     color="#5B5B5B"
                     lineHeight="16px"
                     fontWeight="400"
                    >End year (or expected)*</FormLabel>
                    <Select
                      {...register('endYear', {
                        required: true,
                        validate: () => {
                          return moment()
                            .set('y', watch('endYear'))
                            .isAfter(moment().set('y', watch('startYear')));
                        },
                      })}
                      sx={{
                        bg:"#FFFFFF",
                        color: "#5B5B5B",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        "::placeholder": {
                          fontSize: "16px",
                          color: "#5B5B5B",

                        },
                      }}
                      placeholder="Select"
                    >
                      {yearOptions()}
                    </Select>
                    <FormErrorMessage>
                      Please select a valid date
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <FormControl w="100%">
                  <HStack
                    borderRadius={5}
                    //bg={'brandGray.200'}
                    bg="#FFFFFF"
                    px={4}
                    py={2}
                    w="100%"
                    alignItems="center"
                    border="1px solid"
                    borderColor="brandGray.100"
                  >
                    <FormLabel    
                        color= "#747474"
                        fontSize= "16px"
                        fontWeight= "400"
                        lineHeight= "20px"
                        flex={1}>I&apos;m over 13</FormLabel>
                         <Text
                         fontFamily="Inter"
       color= "#747474"
       fontSize= "16px"
       fontWeight= "400"
       lineHeight= "20px"
      >
        No
      </Text>
                    <Switch
                      {...register('eligibility', {
                        // validate: (value) => !value,
                      })}
                      defaultChecked={true}
                    />
                  </HStack>
                  <FormErrorMessage>test</FormErrorMessage>
                </FormControl>
                {!watch('eligibility') ? (
                  <FormControl isInvalid={errors.dob}>
                  <FormLabel fontWeight="bold">Date of Birth</FormLabel>
                  <Controller
                    name="dob"
                    control={control}
                    defaultValue={{ day: 1, month: 1, year: 1990 }} // Default DOB
                    rules={{
                      validate: (val) => {
                        const { day, month, year } = val || {};
                        return (
                          day &&
                          month &&
                          year &&
                          moment().diff(
                            moment({ year, month: month - 1, day }),
                            'years'
                          ) > 13
                        );
                      },
                    }}
                    render={({ field }) => (
                      <DateOfBirthInput
                        value={field.value}
                        onChange={field.onChange}
                        required
                      />
                    )}
                  />
                  <FormErrorMessage>
                    We love having ambitious people like you on myBridge, but you must be over 13 years old.
                  </FormErrorMessage>
                </FormControl>
                ) : (
                  ''
                )}
              </>
            )}
            <HStack justifyContent="center">
              <Link 
                  fontFamily='Inter'
                  fontSize='16px'
                  color="#5B5B5B"
                  lineHeight="13.92px"
                  fontWeight="500"
                 onClick={handleModeChange}>
                {mode === 0 ? "I'm a student" : "I'm am not a student"}
              </Link>
            </HStack>
            <Button fontWeight="600" borderRadius="30px" height="48px" isLoading={loading} variant="primary" type={'submit'}>
              Next
            </Button>
            <Button fontFamily='Inter'
                  fontSize='16px'
                  color="#5B5B5B"
                  lineHeight="19.2px"
                  borderRadius="30px" height="48px"
                  fontWeight="500"  variant="ghost" onClick={back}>

              Back
            </Button>
          </Stack>
        </form>
      )}
    </Stack>
  );
};
