import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { HStack } from '@mybridge/ui/layout';
import { Select } from '@mybridge/ui/select';
import moment from 'moment';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export const DateOfBirthInput = forwardRef(
  (
    { value, onChange, offset = 0, asElement = false, required = false },
    ref
  ) => {
    console.log(value,'dkdkdkdk1');
    
    const [day, setDay] = useState(value?.day ?? 1);
    const [month, setMonth] = useState(value?.month ?? 1);
    const [year, setYear] = useState(value?.year ?? 1990);

    const yearOptions = useMemo(() => {
      const year = moment().year() - offset;
      return Array.from(Array(50)).map((_, ind) => (
        <option key={ind} value={year - ind}>
          {year - ind}
        </option>
      ));
    }, []);
    const monthOptions = useMemo(() => {
      return Array.from(Array(12)).map((_, ind) => (
        <option key={ind} value={ind + 1}>
          {moment().set('M', ind).format('MMM')}
        </option>
      ));
    }, [yearOptions, year]);

    const dayOptions = useMemo(() => {
      return Array.from(
        Array(
          moment()
            .set('y', year)
            .set('M', month - 1)
            .daysInMonth()
        )
      ).map((_, ind) => (
        <option key={ind} value={ind + 1}>
          {ind + 1}
        </option>
      ));
    }, [yearOptions, monthOptions, month, year]);

    const onChange_ = () => {
      if (asElement) {
        const obj = { target: { value: { day, month, year } } };
        console.log(obj,"dkdkdkdk");
        // onChange?.({ target: { value: { day, month, year } } });
        onChange?.(obj);
      } else {
        onChange?.({ day, month, year });
      }
    };
    useEffect(() => {
      onChange_?.();
    }, [day, month, year]);

    return (
      <HStack>
        <FormControl>
          <FormLabel         
              fontFamily="Inter"
              color= "#5B5B5B"
              fontSize= "16px"
              fontWeight= "400"
              lineHeight= "16px"
          >Month{required ? '*' : ''}</FormLabel>
          <Select
            value={month}
            onChange={(e) => setMonth(parseInt('' + e.target.value))}
            sx={{bg:'#FFFFFF'}}
          >
            {monthOptions}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel
              fontFamily="Inter"
              color= "#5B5B5B"
              fontSize= "16px"
              fontWeight= "400"
              lineHeight= "16px"
          >Day{required ? '*' : ''}</FormLabel>
          <Select
            value={day}
            onChange={(e) => setDay(parseInt('' + e.target.value))}
            sx={{bg:'#FFFFFF'}}
          >
            {dayOptions}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel
              fontFamily="Inter"
              color= "#5B5B5B"
              fontSize= "16px"
              fontWeight= "400"
              lineHeight= "16px"
          >Year{required ? '*' : ''}</FormLabel>
          <Select
            value={year}
            onChange={(e) => setYear(parseInt('' + e.target.value))}
            sx={{bg:'#FFFFFF'}}
          >
            {yearOptions}
          </Select>
        </FormControl>
      </HStack>
    );
  }
);
