/* eslint-disable @nx/enforce-module-boundaries */
// 'use client';
import { Divider, Heading, Scrollable } from '@mybridge/ui';
import { Card } from '@mybridge/ui/card';
import { Box, HStack, Stack, VStack } from '@mybridge/ui/layout';
import { css } from '@emotion/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainCopyrightsText } from 'v4/components/copyrights-text';
import CalendarSidebar from 'v4/snippets/calendar/calendar-sidebar';
import { PrependPageHeadTitle } from 'v4/snippets/custom-head';
import { DashboardCalendar } from 'v4/snippets/dashboard/calendar';
import { DashboardMyCircle } from 'v4/snippets/dashboard/circle';
import { DashboardFeed } from 'v4/snippets/dashboard/feed';
import { ImportContacts } from 'v4/snippets/dashboard/import-contacts';
import { DashboardPostForm } from 'v4/snippets/dashboard/post-form';
import { SiteShortcutLinks } from 'v4/snippets/dashboard/site-shortcut-links';
import { AuthenticatedProviders } from 'v4/snippets/providers/authenticated';
import { SearchContext } from 'v4/snippets/search/context';
import { SearchLayout } from 'v4/snippets/search/layout';
import { SearchPosts } from 'v4/snippets/search/posts';
import { getMatrixEncryption } from 'v4/store/actions/auth.actions';
import {
  chatLogin,
  chatRegisterSession,
  chatRegister,
} from 'v4/store/actions/chat.actions';
import {
  getFeelingsAndActivities,
  setMobileDevice,
  setTabletDevice,
} from 'v4/store/actions/global.data.actions';
import CryptoJS from 'crypto-js';
import Head from 'next/head';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import UpcomingMeetings from '../calendar/upcoming-meetings';
import { GlobalContext } from '../global/context';
import SponsoredSpotlights from '../dashboard/sponsored-spotlights';
import { useCurrentLocation, LocationContext } from 'v4/lib/hooks/use-location';
import styles from '../../components/header/status-dropdown/statusProfile.module.scss';

export const HomePage = ({}) => {
  const { push, query: locationQuery } = useRouter();
  const { loggedIn, pageLoaded } = useSelector((state) => state.user);
  const { headerGlobalNotifCount } = useSelector((state) => state.globalData);
  const { globalNotifCount } = useSelector((state) => state.notifications);
  const accessToken =
    typeof window !== 'undefined' && localStorage.getItem('chatAccess');
  const { userProfileInfo, loading } = useSelector(
    (state) => state.userProfile
  );
  const isDashboardClicked = useSelector(
    (state) => state?.dashboardActive?.isDashboardClicked
  );
  const { selectedDashboard } = useSelector((state) => state.calendar);

  const { is_active, is_onboarding_completed } = userProfileInfo ?? {};
  const { query, refetchPostsSearch } = useContext(SearchContext);
  const { pageHeadPrepend } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const { setupMatrixClient, matrixClient } = useContext(MatrixContext);
  const location = useCurrentLocation({ enabled: true, searchPlace: false });
  useEffect(() => {
    if (loggedIn) {
      dispatch(getFeelingsAndActivities());
      if (!matrixClient) {
        setupMatrixClient();
      }
    } else {
      refetchPostsSearch();
    }
  }, [loggedIn]);

  // useEffect(() => {
  //   if (chatLoggedIn) {
  //     setupMatrixClient();
  //   }
  // }, [chatLoggedIn, chatAccessToken]);

  if (!pageLoaded) return <></>;

  if (
    !loggedIn ||
    query?.query?.length > 0
    // || !is_onboarding_completed
  )
    return (
      <SearchLayout activeTab="home">
        <Head>
          <title>Home | myBridge.me</title>
          <meta
            name="description"
            content="myBridge.me: Professional networking for business pros & job seekers. Connect, collaborate, advance. Your bridge to career success."
          />
          <meta
            name="keywords"
            content="Job Website USA, Professional Job Website USA, Job portals in usa
, Best job posting sites, Social Networking Websites, Top 10 Social media platforms, Popular social networking websites, Calendar, Free online schedule maker, Online file storage for business, Free Online Meeting Platform & Solution, Free Chat Rooms, Create Custom Event Websites"
          />

          <link
            rel="canonical"
            href={`${typeof window !== 'undefined' && window.location.href}`}
          />
        </Head>
        <Heading as="h1" opacity="0" visibility="hidden" h="0px" w="0px">
          New to myBridge?
        </Heading>
        <Heading as="h2" opacity="0" visibility="hidden" h="0px" w="0px">
          Are you new to myBridge?
        </Heading>
        <SearchPosts />
      </SearchLayout>
    );
  return (
    <AuthenticatedProviders>
      {/* <LocationContext.Provider value={location}> */}
      {!query?.query && (
        <Head>
          <title>
            {loggedIn && headerGlobalNotifCount
              ? `(${headerGlobalNotifCount})`
              : ''}{' '}
            Home | myBridge.me
          </title>
          <meta
            name="description"
            content="Mybridge.me is a largest professional networking Website for business professionals and job seekers. myBridge has redefined the way we connect in digital age."
          />
          <meta
            name="keywords"
            content="Job Website USA, Professional Job Website USA, Job portals in usa
, Best job posting sites, Social Networking Websites, Top 10 Social media platforms, Popular social networking websites, Calendar, Free online schedule maker, Online file storage for business, Free Online Meeting Platform & Solution, Free Chat Rooms, Create Custom Event Websites"
          />
        </Head>
      )}
      <Heading as="h1" opacity="0" visibility="hidden" h="0px" w="0px">
        New to myBridge?
      </Heading>
      <Heading as="h2" opacity="0" visibility="hidden" h="0px" w="0px">
        Are you new to myBridge?
      </Heading>
      <HStack
        flexDir={['column', 'column', 'row']}
        alignItems="flex-start"
        justifyContent="center"
        // spacing={0}
        w="100%"
        backgroundColor="rgb(255, 255, 255)"
        // display={((isMobile || isTablet) && !isDashboardClicked) && 'none'} 
        {...(isMobile&& isDashboardClicked ? { display: 'none' } : {})}
        
      >
        {/* {!isMobile && (
          <Stack
            display={['none', 'none', 'flex']}
            w={['100%', '100%', '350px']}
            bg="white"
            boxShadow="2px 0px 6px 0px #00000033"
            me={2}
            minH="100vh"
            h="100%"
            pos="sticky"
            top="64px"
          >
            <Scrollable maxH="88vh" height="100%">
              <VStack
                spacing={2}
                pb={3}
                justifyContent="space-between"
                h="100%"
                align="stretch"
                minH="calc(100vh - 64px)"
              >
                <VStack spacing={2} align="stretch">
                  <DashboardMyCircle />
                  <Divider />
                  <SiteShortcutLinks />
                </VStack>
                <ImportContacts />
                <MainCopyrightsText />
              </VStack>
            </Scrollable>
          </Stack>
        )} */}
        {!isMobile && (
          <Stack
            display={['none', 'none', 'flex']}
            className={styles.left}
            // w={['90px', '90px', '280px']}
            pos="sticky"
            top="64px"
            spacing={2}
            // bg="white"
            // boxShadow="2px 0px 6px 0px #00000033"
            minH="90vh"
            borderBottom="1px solid #e0e0e094"
            height={'500px'}
            zIndex='999'

            // overflowX={"hidden"}
            // overflowy={"auto"}
          >
            {/* <Scrollable maxH="99vh" flex={1}> */}

            <VStack
              spacing={2}
              // w={['200px', '20px', '20px']}
              justifyContent="space-between"
              height="100%"
              align="stretch"
              h="calc(100vh - 0px)"
              className={styles.paddingLeftSmall}
              backgroundColor="rgb(255, 255, 255)"
              // overflowY={"auto"}
              // overflowX={"hidden"}
            >
              <VStack spacing={2} align="stretch" h="100%" flex="1">
                <SiteShortcutLinks />

                <Box className={styles.hideTitle}>
                  <DashboardMyCircle h="300px" flex="1" />
                </Box>
              </VStack>
              {/* <ImportContacts /> */}
              {/* <MainCopyrightsText /> */}
            </VStack>
            {/* </Scrollable> */}
          </Stack>
        )}
        {isMobile&&(<Stack
          spacing={0}
          w={['100%', '500px', '700px']}
          borderTopRightRadius="10px"
          borderTopLeftRadius="10px"
          border="1px solid #e0e0e094"
          pt={1}
         
        >
          {isDesktop ? (
            <Card pt={6} p={4} variant="thinBorderedCard">
              <DashboardPostForm />
            </Card>
          ) : (
            <DashboardPostForm />
          )}

          <DashboardFeed location={location} />
        </Stack>)}
        {!isMobile&&(<Stack
          spacing={0}
          w={['100%', '500px', '700px']}
          borderTopRightRadius="10px"
          borderTopLeftRadius="10px"
          border="1px solid #e0e0e094"
          pt={1}
          css={css`
            @media screen and (max-width: 1300px) {
              width: 600px;
            }
          `}
        >
          {isDesktop ? (
            <Card pt={6} p={4} variant="thinBorderedCard">
              <DashboardPostForm />
            </Card>
          ) : (
            <DashboardPostForm />
          )}

          <DashboardFeed location={location} />
        </Stack>)}
        {!isMobile && (
          <Scrollable
            pos="sticky"
           minH="90vh"
            maxH="92vh"
            top="64px"
            w={['130px', '270px', '350px']}
          >
            <Stack
              spacing={2}
              // bg="white"
              display={['none', 'none', 'flex']}
              w={['100%', '100%', '330px']}
              // boxShadow="-2px 0px 6px 0px #00000033"
              // ms={2}
              h="100vh"
              sx={{
                '@media (max-width: 1028px)': {
                  // Adjust the max-width value based on your needs
                  display: 'none',
                },
              }}
            >
              <Box
                p={2}
                borderRadius={'30px'}
                mt={4}
                ml={2}
                border="1px solid #e0e0e094"
              >
                <DashboardCalendar />
              </Box>
              <Box
                p={2}
                borderRadius={'30px'}
                mt={2}
                ml={2}
                border="1px solid #e0e0e094"
              >
                {/* <Divider /> */}
                <CalendarSidebar isDashboard={true} />
              </Box>
              {/* <Divider /> */}
              <Box
                p={2}
                borderRadius={'30px'}
                mt={2}
                ml={2}
                border="1px solid #e0e0e094"
              >
                <UpcomingMeetings isDashboard={true} />
                {/* <Divider /> */}
              </Box>
              <Box mt={3}>
                <SponsoredSpotlights location={location} />
              </Box>
            </Stack>
          </Scrollable>
        )}
      </HStack>
      {/* </LocationContext.Provider> */}
    </AuthenticatedProviders>
  );
};
