/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Card,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { SuggestedUserCard } from 'v4/components/user-card/suggested';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { Input, InputGroup, InputLeftElement, InputRightElement, IconButton } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";



export const MyNetworkSuggestionsListing = forwardRef(
  ({ fromOnboarding=false,limit = fromOnboarding?7:2, ...props }, ref) => {
    const {
      suggestions,
      refetchSuggestions,
      suggestionsIsFetching,
      suggestionsIsLoading,
    } = useContext(MyNetworkContext);
    const [showAll, setShowAll] = useState(false);
    const [displayLimits, setDisplayLimits] = useState({});
    const { pathname,push } = useRouter();


    const [searchQuery, setSearchQuery] = useState("");
    const [initialResults, setInitialResults] = useState({ degree: [], follow: [], industry: [] });
    const [results, setResults] = useState({ degree: [], follow: [], industry: [] });
  
    // Set initial results when the component mounts
    useEffect(() => {
      setInitialResults({
        degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry
      });
      setResults({
        degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry
      });
    }, [suggestions?.degree, suggestions?.follow, suggestions?.industry]);
  
  
    const handleSearch = (e) => {

      const searchTerm = e.target.value.toLowerCase();
      setSearchQuery(searchTerm);
      if (searchTerm === "") {
        setResults(initialResults);
      } else {
        const filteredDegree = initialResults?.degree.filter((item) =>
              ["first_name", "last_name", "display_name", "position", "location"]
                .some((key) => item[key]?.toLowerCase().includes(searchTerm))
            );
        
        const filteredFollow = initialResults?.follow.filter((item) =>
          ["first_name", "last_name", "display_name", "position", "location"]
            .some((key) => item[key]?.toLowerCase().includes(searchTerm))
        );


        const industryFollow = initialResults?.industry.filter((item) =>
          ["first_name", "last_name", "display_name", "position", "location"]
            .some((key) => item[key]?.toLowerCase().includes(searchTerm))
        );
        
        
        setResults({ degree: filteredDegree, follow: filteredFollow, industry: industryFollow });
      }
    };

    const handleClear = () =>{
      setSearchQuery("");
      setResults({  degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry })

    }
  


    useEffect(() => {
      refetchSuggestions?.();
    }, []);

    const isNetworkPage = pathname === '/profile/network';
let users=[ {
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},{
  "id": 697,
  "first_name": "Asim",
  "last_name": "70",
  "profile_pic": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "slug": "asim-dt3y",
  "location": "Bhopal, Madhya Pradesh, India, India",
  "user_uuid": "0dea3c21-f386-4480-be11-378b395cba5a",
  "position": "Automation Test",
  "company": "Bridgestone",
  "experiences": [
    {
      "id": 364,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description\nDescription Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "2023",
      "currently_working": true,
      "audience": 1,
      "created_at": "2023-08-30T14:57:20.628945Z",
      "updated_at": "2023-08-30T14:57:20.628981Z",
      "user": 697
    },
    {
      "id": 358,
      "title": "Automation Test",
      "employment_type": "FT",
      "company_name": "Bridgestone",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "",
      "industry": "Banking - Financial Services",
      "desc": "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
      "profile_headline": "Automation Test",
      "start_month": "0",
      "start_year": "2021",
      "end_month": "0",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-08-29T08:53:17.979719Z",
      "updated_at": "2023-08-30T14:58:48.938210Z",
      "user": 697
    }
  ],
  "educations": [],
  "matrix_user_id": "@asim_70_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 1,
  "followers_count": 6,
  "first_degree_count": 6,
  "former_name": null,
  "display_name": "Asim 70",
  "public_profile_link": "https://v4.bybk.org/p/asim-dt3y",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/0dea3c21-f386-4480-be11-378b395cba5a/ultra-pack-green-letters-40mb-2AAG3WM_2023-10-05_072605.920254.jpg",
  "profile_pic_chars": "A7",
  "feelings_activity": null,
  "mutual_connections": [
    {
      "id": 692,
      "first_name": "Edan",
      "last_name": "Desilva",
      "slug": "edan",
      "location": "Boston Road,NY,USA",
      "former_name": "D",
      "email": "ketan.sonawane+86@databridgeconsultants.com",
      "role": null,
      "profile_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/Nik_Kraus_2024-04-08_090752.225174.jpg",
      "profile_banner_pic": "https://cdn.bybk.org/media/profile/8846944d-583c-429d-85cf-0d9de0a75f88/SamplePNGImage_1mbmb_2023-11-20_132736.663690.png"
    }
  ],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh, India India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 756,
  "first_name": "maulik11",
  "last_name": "goyani",
  "profile_pic": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "slug": "maulik11",
  "location": "Surat, Gujarat, India",
  "user_uuid": "063d2b45-b269-46c5-bb9c-52dbf02e93da",
  "position": "das",
  "company": "",
  "experiences": [
    {
      "id": 420,
      "title": "das",
      "employment_type": "FT",
      "company_name": "daddy’s",
      "company_image": null,
      "city": null,
      "country": null,
      "state_region": null,
      "zipcode": null,
      "location": "dsa dad das",
      "industry": "Banking - Financial Services",
      "desc": "Dsadasdasda",
      "profile_headline": "No headline",
      "start_month": "10",
      "start_year": "2021",
      "end_month": "10",
      "end_year": "2021",
      "currently_working": false,
      "audience": 1,
      "created_at": "2023-10-27T09:52:49.426475Z",
      "updated_at": "2023-10-27T11:18:09.942699Z",
      "user": 756
    }
  ],
  "educations": [],
  "matrix_user_id": "@maulik.goyani_11_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": "Busy",
  "mutual_connection_count": 0,
  "followers_count": 0,
  "first_degree_count": 1,
  "former_name": null,
  "display_name": "maulik11 goyani",
  "public_profile_link": "https://v4.bybk.org/p/maulik11",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/063d2b45-b269-46c5-bb9c-52dbf02e93da/4237989137_2023-10-27_100913.841286.png",
  "profile_pic_chars": "MG",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Surat Gujarat India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},
{
  "id": 883,
  "first_name": "Jhon",
  "last_name": "Abraham",
  "profile_pic": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "slug": "jhon-i9ap",
  "location": "Bhopal, Madhya Pradesh, India",
  "user_uuid": "5ee4a6dc-23ac-494c-864f-64cdf798e9ec",
  "position": "Accountant",
  "company": "Bank of America",
  "experiences": [
    {
      "id": 580,
      "title": "Accountant",
      "employment_type": "FT",
      "company_name": "Bank of America",
      "company_image": null,
      "city": "Altoona",
      "country": "United States",
      "state_region": "Iowa",
      "zipcode": null,
      "location": "Altoona, Iowa, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial Services",
      "profile_headline": "Accountant",
      "start_month": "0",
      "start_year": "2022",
      "end_month": "0",
      "end_year": "",
      "currently_working": true,
      "audience": 1,
      "created_at": "2024-05-06T12:22:08.717384Z",
      "updated_at": "2024-05-06T12:31:23.626834Z",
      "user": 883
    },
    {
      "id": 582,
      "title": "Financial Advisor",
      "employment_type": "CT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": null,
      "country": "United States",
      "state_region": null,
      "zipcode": null,
      "location": "United States",
      "industry": "",
      "desc": "",
      "profile_headline": "Financial Advisor",
      "start_month": "4",
      "start_year": "2020",
      "end_month": "2",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:33:11.217219Z",
      "updated_at": "2024-05-06T12:33:11.217250Z",
      "user": 883
    },
    {
      "id": 581,
      "title": "Finance Analyst",
      "employment_type": "FT",
      "company_name": "JPMorgan Chase",
      "company_image": null,
      "city": "Elk Grove Village",
      "country": "United States",
      "state_region": "Illinois",
      "zipcode": null,
      "location": "Elk Grove Village, Illinois, United States",
      "industry": "Banking - Financial Services",
      "desc": "Banking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial ServiceBanking - Financial Service",
      "profile_headline": "Finance Analyst",
      "start_month": "0",
      "start_year": "2020",
      "end_month": "3",
      "end_year": "2022",
      "currently_working": false,
      "audience": 1,
      "created_at": "2024-05-06T12:32:16.569331Z",
      "updated_at": "2024-05-06T12:32:16.569363Z",
      "user": 883
    }
  ],
  "educations": [
    {
      "id": 343,
      "name": "Academy of Art College",
      "start_year": "2010",
      "start_month": "0",
      "end_year": "2012",
      "end_month": "0",
      "is_graduate": true,
      "desc": "",
      "degree": "American Society for Psychoprophylaxis in Obstetrics",
      "courses": "java, Cloud, AWS",
      "type": "College",
      "audience": 1,
      "created_at": "2024-05-06T12:26:38.037979Z",
      "updated_at": "2024-05-06T12:26:38.038012Z",
      "user": 883
    }
  ],
  "matrix_user_id": "@asim_506_databridgeconsultants.com:chat.mybridge.me",
  "matrix_presence": "offline",
  "matrix_status": null,
  "mutual_connection_count": 0,
  "followers_count": 2,
  "first_degree_count": 0,
  "former_name": null,
  "display_name": "Jhon Abraham",
  "public_profile_link": "https://v4.bybk.org/p/jhon-i9ap",
  "default_profile_picture": "https://cdn.bybk.org/media/profile/5ee4a6dc-23ac-494c-864f-64cdf798e9ec/unnamed_2024-05-06_122222.909813.jpg",
  "profile_pic_chars": "JA",
  "feelings_activity": null,
  "mutual_connections": [],
  "profile_banner_pic": null,
  "display_location": "Bhopal Madhya Pradesh India",
  "field_audience_visibility": {
    "MENTIONS_AND_TAGS": true,
    "WHO_CAN_SEE_FRIENDS": 1,
    "WHO_CAN_SEND_FRIEND_REQ": 1
  }
},]
   const renderCategory = (title, items, key, maxLimit) => {
    
   const currentLimit = displayLimits[key] || maxLimit;

   const displayedItems = Array.isArray(items) ? items.slice(0, currentLimit) : []
 
  const handleSeeMore = () => {
    setDisplayLimits((prev) => ({
      ...prev,
      [key]: Math.min((prev[key] || maxLimit) + 7, items.length),
    }));
  };

  const handleShowAll = () => {
    setShowAll(true);
      console.log(key)
      setDisplayLimits((prev) => ({
        ...prev,
        [key]: items.length,
      }));
    
  };


 
      
      if (!items?.length) return null;

      return (
        <Box p={[0,4]} pt={[0, 4, 4]} px={isMobile? 3 : 0}>
          <HStack w="90%" justifyContent="space-between">
            <Heading
              fontSize="20px"
              fontWeight="400"
              color="brandPrimary.500"
              mb={isMobile && isNetworkPage ? 0 : 3}
            >
              {title}
            </Heading>
           
      
            {!fromOnboarding && items.length !== displayedItems.length  && !(isMobile && isNetworkPage) && (
              <Button
                variant="transparent"
                onClick={() => handleShowAll(key,items)} 
                color="#868585"
                fontWeight="500"
              >
                See All
              </Button>
            )}
            
          </HStack>
          <Wrap spacing={3}>
            {[...displayedItems].map((pi, piIndex) => (
              <WrapItem
                maxW={['49%', '30%', '190px']}
                flexGrow={['1', '1', 0]}
                flexShrink={['1', '1', 0]}
                flex="auto"
                key={piIndex}
                mb="10px"
              > 
                <SuggestedUserCard fromOnboarding={fromOnboarding} user={pi} />
              </WrapItem>
            ))}
{fromOnboarding && items.length !== displayedItems.length && !(isMobile && isNetworkPage) && (
              <Button
                variant="transparent"
                alignItems={"start"}
                onClick={() => handleSeeMore(key,items)} 
                // color="#868585"
                fontWeight="500"
                color="brandPrimary.500"
                textDecoration="underline"
              >
                See More
              </Button>
            )}
          </Wrap>
          
        </Box>
      );
    };

    return (
      <Stack spacing={3}>
        {suggestionsIsFetching || suggestionsIsLoading ? (
          <HStack justifyContent="center">
            <Spinner />
          </HStack>
        ) : isMobile && isNetworkPage ? (
          <>
            {renderCategory(
              '',
              [...(suggestions?.role ?? []), ...(suggestions?.industry ?? [])],
              'role_and_industry',
              limit
            )}
          </>
        ) : (
          <>
 {fromOnboarding&&(         <Box marginTop="10px" marginBottom="-18px" width="100%" display="flex" justifyContent="center">
          {/* <Box width = "100%" maxWidth="600px">
           <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        height="48px"
        placeholder="Search for someone specific to connect with..."
        value={searchQuery}
        onChange={handleSearch}
        variant="outline"
        focusBorderColor="#D9D9D9"
        borderRadius={"30px"}
      />
      {searchQuery && (
        <InputRightElement>
          <IconButton
            size="sm"
            icon={<CloseIcon />}
            onClick={handleClear}
            sx={{
              bgColor:'transparent',
              ":hover":{
                bgColor:'transparent'
              }
            }}
            aria-label="Clear search"
          />
        </InputRightElement>
      )}
          </InputGroup>
          </Box> */}
         </Box> )}
            {renderCategory(
              `People you may know from ${
                suggestions?.university?.[0]?.educations?.[0]?.name?.name ??
                'similar university'
              }`,
              suggestions?.university,
              'university',
              limit
            )}
            {renderCategory(
              'People you may know based on your Location',
              suggestions?.location,
              'location',
              limit
            )}
            {renderCategory(
              fromOnboarding?'Trending people to follow':'Trending people in your network to follow',
              //results?.follow,
              [...(results?.follow ?? []), ...(users ?? [])],
              'follow',
              limit
            )}
            {renderCategory(
              'People you may know based on your Degrees',
              //results?.degree,
              [...(results?.degree ?? []), ...(users ?? [])],
              'degree',
              limit
            )}
            {renderCategory(
              'People you may know with similar roles and industry',
              //[...(suggestions?.role ?? []), ...(results?.industry ?? [])],
              [...(suggestions?.role ?? []), ...(results?.industry ?? []),...(users ?? [])],
              'role_and_industry',
              limit
            )}
            {!suggestions?.university?.length &&
            !results?.follow?.length &&
            !results?.degree?.length &&
            !suggestions?.role?.length &&
            !results?.industry?.length &&
            !suggestions?.location?.length ? (
              <HStack justifyContent="center">
                <Text mt={16}>No suggestions for now! Please come back later...</Text>
              </HStack>
            ) : null}
          </>
        )}
      </Stack>
    );
  }
);
