/* eslint-disable @nx/enforce-module-boundaries */
import { ExpandIcon } from '@mybridge/icons/Expand';
import { ZoomOutIcon } from '@mybridge/icons/ZoomOutIcon';
import { ZoomInIcon } from '@mybridge/icons/ZoomInIcon';

import { Card, CloseButton, IconButton, Image, Scrollable,Text } from '@mybridge/ui';
import { MyBridgeLogoMain } from '@mybridge/icons/MyBridgeLogo';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack, VStack } from '@mybridge/ui/layout';
import Link from 'next/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Spinner } from '@mybridge/ui/spinner';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PostBoxContext, usePostBoxContext } from 'v4/components/post/context';
import { ModalMediaDisplay } from 'v4/components/post/modal-media-display';
import { getPostFunc } from 'v4/lib/api/post';
import { getUserFullName } from 'v4/lib/commons';
import { DashboardPostBox } from '../feed/post-box';
import { isMobile } from 'react-device-detect';
import { BackArrow } from '@mybridge/icons';

export const DashboardPostContainer = ({
  postId,
  onClose,
  setHasMedia,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [showMediaPost, setShowMediaPost] = useState(false);
  const name = "'s post"

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const { deletedPosts } = useSelector((state) => state.posts ?? {});
  const [zoom, setZoom] = useState(1); // Zoom state
  const maxZoom = 3; // Maximum zoom level
  const minZoom = 1; // Minimum zoom level
  // query for fetching single post
  const { refetch: fetchPost, data } = useQuery(
    ['getPostDetail', postId],
    getPostFunc,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const postBoxContext = usePostBoxContext({
    post: data,
    initialFetchComments: true,
  });
  const { refetch, refetchComments, post, hasMedia = true } = postBoxContext ?? {};
  
console.log(hasMedia,"");

  useEffect(() => {
    setHasMedia?.(hasMedia);
  }, [hasMedia]);

  useEffect(() => {
    if (deletedPosts?.includes?.(post?.id)) {
      props?.onClose?.();
    }
  }, [deletedPosts, post]);

  useEffect(() => {
    setTimeout(() => {
      refetch?.();
      refetchComments?.();
    }, 0);
  }, []);

  useEffect(() => {
    const getLocalStorage = localStorage.getItem('commentCLicked');
    setShowMediaPost(getLocalStorage);
    fetchPost?.();
  }, []);

  const handleOnClose = (e) => {
    
    localStorage.removeItem('commentCLicked');
    onClose?.();
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, maxZoom)); // Max zoom level is 3
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, minZoom)); // Min zoom level is 1
  };

  return (
    <PostBoxContext.Provider value={postBoxContext}>
      {!hasMedia ? (
        <ModalHeader borderBottom="1px solid #ddd" px={2}>
          <HStack
          // style={{ position: 'sticky', top: 0 }}
          // bg="white"
          // zIndex={1}
          // as={Card}
          // p={[2, 4]}
          // pos="sticky"
          // top={0}
          // borderRadius="10px 10px 0 0"
          >
            {isMobile && <BackArrow onClick={handleOnClose} />}
            <Heading
              size={['sm', 'md']}
              flex={1}
              textAlign="center"
              color="#3D5A80"
              fontWeight="400"
              zIndex={2}
            >
           {getUserFullName(post?.author) && `${getUserFullName(post?.author)} ${name}`}
   
            </Heading>
            {!isMobile && <CloseButton onClick={handleOnClose} />}
          </HStack>
        </ModalHeader>
      ) : null}
      <ModalBody   style={{
              scrollbarGutter: 'stable', // Adds space between content and scrollbar
            }} p={0} className="postModalContainer">
        {loading ? (
          <HStack
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            zIndex="5"
            bg="white"
          >
            <Spinner size="sm" color="black" />
            <VStack>
            <Text>Post is loading..</Text>
            </VStack>
          </HStack>
        ) : (
          ""
        )}
        <HStack
          flexDirection={['column', 'column', 'row']}
          alignItems={!isMobile?"stretch":"center"}
          spacing={0}
          minH={hasMedia ? '100vh' : ''}
          pos="relative"
        >
          {hasMedia && !post?.removed ? (
            <>
              <HStack
                pos="fixed"
                top="0"
                left="0"
                right="0"
                zIndex="2"
                px={2}
                maxW={['100%', '100%', expanded ? '100%' : '70%']}
              >
                <HStack bg="transparent" color="white">
                  {!isMobile && <CloseButton onClick={handleOnClose} />}
                </HStack>

                <Box flex={1} p={2}>
                  <Link href="/" as="/">
                    <MyBridgeLogoMain width={isMobile ? '30px' : '44px'} />
                  </Link>
                </Box>

                <HStack
                  borderRadius="full"
                  bg="transparent"
                  justifyContent="end"
                >
                  <IconButton
                   opacity= {zoom >= maxZoom ? 0.5 : 1}
                   cursor= {zoom >= maxZoom ? 'not-allowed' : 'pointer'}
                    onClick={handleZoomIn}
                    variant="ghost"
                    color="#5B5B5B"
                    _hover={{ bg: 'transparent' }}
                  >
                    <ZoomInIcon width="20" height="20" />
                  </IconButton>
                  <IconButton
                   opacity= {zoom <= minZoom ? 0.5 : 1}
                   cursor= {zoom <= minZoom ? 'not-allowed' : 'pointer'}
                    onClick={handleZoomOut}
                    variant="ghost"
                    color="#5B5B5B"
                    _hover={{ bg: 'transparent' }}
                  >
                    <ZoomOutIcon width="20" height="20" />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      setExpanded(!expanded);
                    }}
                    variant="ghost"
                    color="#5B5B5B"
                    _hover={{ bg: 'transparent' }}
                  >
                    <ExpandIcon width="20" height="20" />
                  </IconButton>
                </HStack>
              </HStack>
              <HStack
                // flex={"1 1 80%"}
                flex={1}
                bg="black"
                maxH={expanded ? '100vh' : ['50vh', '50vh', '100vh']}
                w="100%"
                maxW={['100%', '100%', expanded ? '100%' : '70%']}
                position="sticky"
                top={0}
                justifyContent="center"
                spacing={0}
                overflowY="hidden"
                _hover={{ overflowY: 'auto' }}
              >
                <ModalMediaDisplay zoom={zoom} fullScreen={expanded} />
              </HStack>
            </>
          ) : (
            ''
          )}
          {post?.id && !expanded ? (
            // <Box
            //   // display={hasMedia && expanded ? 'none' : 'flex'}
            //   zIndex={1}
            //   // spacing={0}
            //   flex={1}
            //   maxW={expanded ? 0 : '100%'}
            //   bg="white"
            //   maxH={hasMedia ? ['50vh', '50vh', '100vh'] : '100vh'}
            // >
            <Box
              flex={['auto', 'auto', 1]}
              maxW={expanded ? 0 : hasMedia&&!isMobile ? '30%' : '100%'}
              maxH="100%"
              overflowY="hidden"
            >
              <DashboardPostBox
                post={post}
                isInFrame={true}
                showMedia={post?.type === 3 || showMediaPost ? true : false}
                showComments={true}
                openedInPopup={true}
                context={postBoxContext}
              />
            </Box>
          ) : null}
        </HStack>
      </ModalBody>
    </PostBoxContext.Provider>
  );
};
