/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { TeamsContext } from '../context';

export const TeamRemoveMemberModal = ({ onClose, title }) => {
  const {
    removeTeamMember,
    team,
    selectedUser,
    teamDetails,
    removeTeamMemberLoading,
    removeMemberDisc,
  } = useContext(TeamsContext);

  return (
    <Modal {...removeMemberDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Remove {getUserFullName(selectedUser)}?</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text display="inline">
              {getUserFullName(selectedUser)} will no longer have access to
              myBridge Project and any team resources within, including all
              files and conversations.
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => removeMemberDisc?.onClose?.()}
            >
              Cancel
            </Button>
            <Button
              isLoading={removeTeamMemberLoading}
              variant="primary"
              colorScheme="danger"
              onClick={(e) => removeTeamMember?.(selectedUser)}
            >
              Remove
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
