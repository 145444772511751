/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';
import { HStack, Text, Box, IconButton } from '@mybridge/ui';
import { CloseIcon } from '@chakra-ui/icons';
import { EventClockIcon, SearchResultIcon } from '@mybridge/icons';
import { useDispatch } from 'react-redux';
import {
  deleteRecentSearchID,
  getRecentSearch,
} from 'v4/store/actions/global.data.actions';
import styles from "./index.module.scss"
const RecentSearchListBox = ({ recent, ...props }) => {
  const dispatch = useDispatch();
  const handleRecentDelete = (e, id) => {
    e.stopPropagation();
    const payload = { id: id };
    dispatch(deleteRecentSearchID(payload)).then((res) => {
      if (res) dispatch(getRecentSearch());
    });
  };

  return (
    <HStack
      {...props}
      justifyContent="space-between"
      alignItems="center"
      align="stretch"
      className={styles.recentSearchDashboard}
    >
      <HStack>
        <Box
          width="30px"
          height="30px"
          borderRadius="full"
          bg="#D7D7D7"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EventClockIcon />
        </Box>
        <Text color="#222222" fontWeight={500}>{recent?.query}</Text>
      </HStack>
      <IconButton
        variant="transparent"
        size="sm"
        onClick={(e) => handleRecentDelete(e, recent?.id)}
        icon={<CloseIcon color="#5B5B5B" width="10px" height="10px" />}
      />
    </HStack>
  );
};

export default RecentSearchListBox;
