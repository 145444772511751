/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { TeamsContext } from '../context';

export const TeamLeaveModal = ({ onClose, title }) => {
  const {
    isOrganizer,
    leaveTeam,
    team,
    teamDetails,
    leaveTeamLoading,
    leaveTeamDisc,
    userProfileInfo,
  } = useContext(TeamsContext);

  console.log('teamDetails', teamDetails, isOrganizer);
  const generalMessage = (
    <Stack>
      <Box mb={4}>
        <Text display="inline">You will no longer be able to access to </Text>
        <Text display="inline" fontWeight="medium">
          "{team?.name ?? teamDetails?.name}"
        </Text>
        <Text display="inline">
          {' '}
          and any team resources, including all files and conversations.
        </Text>
      </Box>
      <Text>Would you still like to leave this team?</Text>
    </Stack>
  );

  return (
    <Modal {...leaveTeamDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Leave this team</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {(isOrganizer || teamDetails?.disk_payer === userProfileInfo?.email &&
            teamDetails?.organizers?.length === 1) ? (
            teamDetails?.organizers?.length <= 1 ? (
              <Stack>
                <Text>Can't leave team as you are the only organizer!</Text>
              </Stack>
            ) : teamDetails?.disk_payer === userProfileInfo?.email ? (
              <Stack>
                <Text>
                  To leave this team, you will need to assign the team's
                  subscription to another organizer.
                </Text>
              </Stack>
            ) : (
              generalMessage
            )
          ) : (
            generalMessage
          )}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => leaveTeamDisc?.onClose?.()}
            >
              Cancel
            </Button>
            {teamDetails?.disk_payer !== userProfileInfo?.email &&
            teamDetails?.organizers?.length >= 1 ? (
              <Button
                isLoading={leaveTeamLoading}
                variant="primary"
                onClick={(e) => leaveTeam?.(team ?? teamDetails)}
              >
                Leave
              </Button>
            ) : (
              ''
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
