/* eslint-disable @nx/enforce-module-boundaries */
import { SearchLineIcon, RemoveNewIcon, RemoveUserIcon } from '@mybridge/icons';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  HStack,
  Input,
  InputGroup,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { TeamsContext } from '../context';
import _ from 'lodash';
export const TeamInviteConnectionsModal = forwardRef(({ ...props }, ref) => {
  const [search, setSearch] = useState();
  const {
    invitationDisc,
    allDegreeConnectionsArr,
    refetchInvitedUsers,
    teamInvitedUsers,
    teamMembers,
    inviteUsersToTeam,
    inviteUsersToTeamLoading,
  } = useContext(TeamsContext);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    refetchInvitedUsers?.();
  }, []);
  const toggleSelected = (item) => {
    const itemId = item?.id;
    const isSelected = selected.some(
      (selectedItem) => selectedItem.id === itemId
    );
    if (isSelected) {
      const updatedSelected = selected.filter(
        (selectedItem) => selectedItem.id !== itemId
      );
      setSelected(updatedSelected);
    } else {
      setSelected([...selected, item]);
    }
  };
  const handleInvite = async () => {
    const data = await inviteUsersToTeam?.(selected);
    props?.handleResponce &&  props.handleResponce(data);
  };

  return (
    <>
      <Modal size="2xl" {...(invitationDisc ?? {})}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text color="#3D5A80" fontSize="22px" lineHeight="26px">
              Invite connections
            </Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Stack>
              <HStack>
                <InputGroup>
                  <Stack position="absolute" zIndex="1" ml="10px" mt="15px">
                    <SearchLineIcon />
                  </Stack>

                  <Input
                    pl="40px"
                    onChange={(e) => setSearch(e?.target?.value)}
                    autoFocus
                    placeholder="Search by name"
                    borderRadius="30px"
                  />
                </InputGroup>
              </HStack>
              <Divider />
              <HStack alignItems="flex-start">
                <Stack maxW="408px" w="380px">
                  {allDegreeConnectionsArr?.[0]?.length ? (
                    <List
                      className="member-list"
                      as={Stack}
                      spacing={0}
                      maxH="64"
                      overflowY="hidden"
                      _hover={{ overflowY: 'auto' }}
                    >
                      {allDegreeConnectionsArr?.[0]
                        ?.filter?.((c) =>
                          search?.length
                            ? getUserFullName(c)
                                ?.toLowerCase?.()
                                ?.includes?.(search?.toLowerCase?.())
                            : true
                        )
                        ?.filter?.(
                          (c) =>
                            !teamInvitedUsers?.find(
                              (iu) => iu?.user?.id === c?.id
                            )
                        )
                        ?.filter?.(
                          (c) =>
                            !teamMembers?.find((iu) => iu?.user?.id === c?.id)
                        )
                        ?.map?.((c, cIndex) => (
                          <ListItem key={cIndex}>
                            <HStack w="100%" alignItems="flex-start">
                              <Checkbox
                                // isChecked={selected?.includes?.(c?.id)}
                                isChecked={selected.some(
                                  (selectedItem) => selectedItem.id === c?.id
                                )}
                                onChange={() => toggleSelected(c)}
                                borderRadius={5}
                                p={2}
                                flex={1}
                                _hover={{ bg: 'gray.100' }}
                              >
                                <HStack alignItems="flex-start">
                                  <Avatar
                                    name={getUserFullName(c)}
                                    src={getUserProfilePic(c)}
                                  />
                                  <Stack spacing={0}>
                                    <Text>{getUserFullName(c)}</Text>
                                    <Text fontSize="sm" color="blackAlpha.500">
                                      {c?.position ??
                                        c?.experiences?.[0]?.name ??
                                        c?.educations?.[0]?.name}
                                    </Text>
                                  </Stack>
                                </HStack>
                              </Checkbox>
                            </HStack>
                          </ListItem>
                        ))}
                    </List>
                  ) : (
                    ''
                  )}
                </Stack>
                {selected?.length && (
                  <Divider orientation="vertical" h="247px" />
                )}
                <Stack maxW="247px" w="247px">
                  {selected?.length ? (
                    <List>
                      <HStack justifyContent="space-between" mb={4}>
                        <Stack cursor="pointer">
                          <Text
                            fontSize="15px"
                            color="#868585"
                            lineHeight="18px"
                          >
                            {selected?.length} Selected
                          </Text>
                        </Stack>
                        <Stack cursor="pointer">
                          <Text
                            fontSize="15px"
                            color="#868585"
                            lineHeight="18px"
                            onClick={() => setSelected([])}
                          >
                            Unselect all
                          </Text>
                        </Stack>
                      </HStack>
                      {selected?.map?.((c, cIndex) => (
                        <ListItem key={cIndex}>
                          <HStack w="100%" alignItems="flex-start" mb={3}>
                            <HStack justifyContent="center" alignItems="center" flex="1">
                              <Avatar
                                name={getUserFullName(c)}
                                src={getUserProfilePic(c)}
                              />
                              <Stack spacing={0} flex="1">
                                <Text>{getUserFullName(c)}</Text>
                              </Stack>
                              <Stack
                                cursor="pointer"
                                onClick={() => toggleSelected(c)}
                              >
                                <RemoveUserIcon />
                              </Stack>
                            </HStack>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    ''
                  )}
                </Stack>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button
                onClick={(e) => invitationDisc?.onClose?.()}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={inviteUsersToTeamLoading}
                onClick={selected?.length>0 && handleInvite}
                disabled={selected?.length}
              >
                Invite {selected?.length > 0 ? selected?.length : ''}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
