/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { Card } from '@mybridge/ui/card';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { Heading } from '@mybridge/ui/heading';
import { Input } from '@mybridge/ui/input';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { Radio, RadioGroup } from '@mybridge/ui/radio';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MultiStepFormContext } from 'v4/components/multi-step-form/context';
import { useLocation } from 'v4/lib/hooks/use-location';
import { useToast } from '@mybridge/ui/hooks';
import {
  getOnboardingData,
  setOnboardingData,
} from 'v4/store/actions/onboarding.actions';
import { UserOnboardingContext } from '../context';
import { useRouter } from 'next/router';

export const UserOnboardingFormJobAvailabilityStep = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { onClose } = useContext(UserOnboardingContext);
  const { next, back, formData, setFields } = useContext(MultiStepFormContext);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast({ position: 'top' });
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const availabilityOptions = [
    {
      title: "Yes, I'm looking for a career change.",
      value: 0,
    },
    {
      title: 'No, but I could be tempted.',
      value: 1,
    },
    {
      title: "No, I'm all set.",
      value: 2,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } =
        (
          await dispatch(
            getOnboardingData({
              step: 'job_availabilty',
              emailId: userProfileInfo?.email,
            })
          )
        )?.payload ?? {};
      if (data) {
        const { job_availabilty } = data ?? {};
        if (job_availabilty) {
          setFields({ jobAvailability: parseInt('' + job_availabilty) });
          setValue('jobAvailability', parseInt('' + job_availabilty));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const save = async (form) => {
    try {
      setLoading(true);
      const payload = {
        step: 'job_availabilty',
        emailId: userProfileInfo?.email,
        data: {
          job_availabilty: form?.jobAvailability,
        },
      };
      console.log(payload);
      const resp = await dispatch(setOnboardingData(payload));
      return true;
    } catch (e) {
      setLoading(false);
      console.log(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onNext = async (form) => {
    const done = await save(form);
    if (done) {
      // toast({
      //   title: 'Onboarding completed successfully!',
      //   status: 'success',
      // });
      // onClose?.();
      // router.push('/')
        next?.();
    }
  };

  const { jobAvailability } = formData ?? {};

  return (
    <Stack>
      <Stack textAlign="center" alignItems="center">
        <Heading size="md" color={"#3D5A80"}>
          Next question: Are you wanting to elevate your career?
        </Heading>
      </Stack>
      <form onSubmit={handleSubmit(onNext)}>
        <Stack spacing={4} pt={4}>
          <FormControl px={2} isInvalid={errors?.jobAvailability}>
            <Controller
              render={({ field }) => {
                const _onChange = field.onChange;
                field.onChange = (v) => _onChange(parseInt(v + ''));
                return (
                  <RadioGroup {...field}>
                    <Stack color = "#5B5B5B" fontWeight="500" spacing={4}>
                      {availabilityOptions.map((ao, aoInd) => (
                        <Radio key={aoInd} value={ao.value}>
                          {ao.title}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                );
              }}
              value={jobAvailability}
              name="jobAvailability"
              rules={{ validate: (val) => val > -1 }}
              control={control}
            />

            <FormErrorMessage>
              Please choose at least one of the options
            </FormErrorMessage>
          </FormControl>
          <Stack>
            <Button borderRadius="30px" height="48px" fontWeight="600" type={'submit'} variant="primary">
              Next
            </Button>
            <Button variant="ghost" borderRadius="30px" height="48px" onClick={back}>
              Back
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
